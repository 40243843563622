import React from "react";
import { Col, Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { ButtonComponent } from '@comps/components';
import { useHistory } from "react-router-dom";

const AboutClient = (props) => {
  const history = useHistory();

  const editUser = () => {
    history.push(`/client-management/clients/edit/${props.data.id}`);
  }

  return (
    <>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 20 }>
          <h5>Client Detail</h5>
        </Col>
        <Col span={ 4 } className="da-text-right">
          <ButtonComponent type="link" onClick={ editUser }> <EditOutlined /></ButtonComponent>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Full Name
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.name }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Email
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.email }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Telephone
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.phone : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Mobile
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.mobile : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Address
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.address_line1 : null }</strong><br />
          <strong>{ props.data.clientInfo ? props.data.clientInfo.address_line2 : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          City
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.city : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Town
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.town : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          Postal Code
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.postal_code : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 22 } className="da-mb-24 da-mt-24">
          <h5>Other Details</h5>
        </Col>
        <Col span={ 12 }>
          Emergency Name
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.partner_name : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          ICE Telephone
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.partner_ice_phone : null }</strong>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 borderbottom da-pb-16">
        <Col span={ 12 }>
          ICE Email
        </Col>
        <Col span={ 12 } className="da-text-right">
          <strong>{ props.data.clientInfo ? props.data.clientInfo.partner_ice_email : null }</strong>
        </Col>
      </Row>

    </>
  );
}

export default AboutClient;
