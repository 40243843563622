import React, { useMemo } from "react";
import { Table } from "antd";
import { useSelector } from 'react-redux';

const BaseTable = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  let { columns, loader, ...otherProps } = props;

  const tab = <><style>{ `.ant-pagination-item-active {border-color:${crmStyle.bg_color};color:${crmStyle.text_color} !important;}.ant-pagination-item:hover {border-color:${crmStyle.bg_color};color:${crmStyle.text_color} !important;}.ant-pagination-item a:hover {color:${crmStyle.text_color}}.ant-pagination-item-active a {color:${crmStyle.text_color}}` }</style>
    <Table rowKey="id" size="small" { ...otherProps } columns={ columns } showSorterTooltip={ false } scroll={ { x: props.scrollDisabled ? 'no' : 'max-content' } } loading={ loader } /></>;// eslint-disable-line react-hooks/exhaustive-deps
  return tab;
};

export default BaseTable;
