import { useState, useEffect } from "react";
import { Col, Row, Input, Form } from "antd";
import { makeRequest, notify } from "@utils/helpers";
import { updateClientNote } from "../../requests";
import { SaveButton } from "@comps/components";

const { TextArea } = Input;

const formName = "noteClient";

const ClientNote = (props) => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {

  }, []);

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.id = props.data.id;
    makeRequest(setLoader, updateClientNote, payload, onSuccess, onError);
  }


  const onSuccess = (data, res) => {
    notify("Client", res.msg);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
  }

  return (
    <>
      <Row gutter={ 16 } className="da-mt-16">
        <Col span={ 24 }>
          <h5>Note</h5>
        </Col>
      </Row>
      <Row gutter={ 16 } className="da-mt-16 ">
        <Col span={ 24 }>
          <Form
            layout="vertical"
            name={ formName }
            initialValues={ props.data.clientInfo }
            onFinish={ onFinish }
          >
            <Form.Item name="note" className="da-mb-16">
              <TextArea rows={ 4 } placeholder="Enter anything" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row gutter={ 16 }>
        <Col span={ 24 } className="da-text-right ">
          <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />
        </Col>
      </Row>

    </>
  );
}

export default ClientNote;
