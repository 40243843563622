
import React, { useEffect, useState, } from "react";
import { Form, Input, Select, Button, Space } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { createInvoiceAddonReq } from "./requests";

const formName = "createInvoiceAddon";

const CreateInvoiceAddon = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);


  const onFinish = (data) => {
    let payload = { "addons": data.option_details }
    payload.id = props.id
    makeRequest(setLoader, createInvoiceAddonReq, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Invoice Additional", res.msg);
    props.onCreated(data);
  }

  const onError = (err) => {
    notify("Invoice Additional", "Something went wrong");
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent width={ 700 } mainTitle="Create" subTitle="ADDITIONAL FEE" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) } >

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Form.List
          name="option_details"
          initialValue={ [
            { title: "", amount: "", is_paid: false },
          ] }
        >
          { (fields, { add, remove }) => (
            <>
              { fields.map(({ key, name, ...restField }) => (
                <Space key={ key } style={ { display: "flex", marginBottom: 8 } } align="baseline">
                  <Form.Item { ...restField } name={ [name, "title"] } rules={ [{ required: true, message: "Please Enter Title" }] }>
                    <Input placeholder="Title" />
                  </Form.Item>
                  <Form.Item { ...restField } name={ [name, "amount"] } rules={ [{ required: true, message: "Please Enter Amount" }] }>
                    <Input type="number" placeholder="Amount" />
                  </Form.Item>
                  <Form.Item { ...restField } name={ [name, "is_paid"] }>
                    <Select
                      style={ { width: 150 } }
                      showSearch
                      filterOption={(d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0}
                      placeholder="Select Status"
                      options={ [{ "value": true, "label": "Paid" }, { "value": false, "label": "Unpaid" }] }
                    />
                  </Form.Item>
                  { fields && fields.length > 1 ? <MinusCircleOutlined onClick={ () => remove(name) } /> : null }
                </Space>
              )) }
              <Form.Item>
                <Button type="dashed" onClick={ () => add() } block icon={ <PlusOutlined /> }>
                  Add field
                </Button>
              </Form.Item>
            </>
          ) }
        </Form.List>


      </Form>
    </ModalComponent>
  );

}
const rules = {
  title: [
    { required: true, message: 'Please input title!', },
  ],

};


export default CreateInvoiceAddon