import React, { useState, createElement, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import {
  Layout,
  Drawer,
  Menu,
  Button,
  Row,
  Col,
} from "antd";

import {
  RiMenuFoldLine,
  RiMenuUnfoldLine,
  RiCloseFill,
} from "react-icons/ri";

import MenuLogo from "./logo";
import navigation from "./navigation";
import { hasPermission } from "@utils/helpers";
import { useSelector } from 'react-redux';

// temp image
import logoSmall from "@assets/img/logo-sm.png";
let all_permissions = [];
const { Sider } = Layout;


export default function Sidebar(props) {
  let webData = localStorage.getItem('webData');
  let webDataObj = JSON.parse(webData);

  const { visible, setVisible } = props;
  const [collapsed, setCollapsed] = useState(false);

  // Location
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/")


  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);

  };

  useEffect(() => {
    all_permissions = JSON.parse(localStorage.getItem("moduleList"))
    if (window.innerWidth < 768) {
      setVisible(false);
    }
  }, []);

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(
    collapsed ? RiMenuUnfoldLine : RiMenuFoldLine,
    {
      className: "trigger",
      onClick: toggle,
    }
  );

  const MainMenu = () => {
    const [openKeys, setOpenKeys] = useState([]);

    const menu = <Menu
      mode="inline"
      defaultOpenKeys={ openKeys }
    >
      { navigation.map((item, index) => MenuItem(item, index, '0')) }
    </Menu>;



    if (!visible) {
      return menu;
    }



    return <Drawer
      title={
        <MenuLogo onClose={ onClose } />
      }
      className="da-mobile-sidebar"
      placement="left"
      closable={ true }
      onClose={ onClose }
      visible={ visible }
      closeIcon={
        <RiCloseFill
          className="remix-icon da-text-color-black-80"
          size={ 24 }
        />
      }
    >
      { menu }
    </Drawer>;
  }
  const crmStyle = useSelector(state => state.common.crmStyle);

  return (
    <Sider
      style={ { background: "#F8F7F8" } }
      trigger={ null }
      collapsible
      collapsed={ collapsed }
      theme="light"
      width={ 256 }
      className="da-sidebar"
    >
      <style>{ `.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {color:${crmStyle.bg_color}}` }</style>
      <Row className="da-mr-12 da-ml-24 da-mt-24" align="bottom" justify="space-between">
        <Col span={ 20 }>
          { collapsed === false ? <MenuLogo onClose={ onClose } /> : "" }
        </Col>

        <Col span={ 4 } className="da-pr-0">
          <Button
            icon={ trigger }
            type="text"
            className="da-float-right"
          ></Button>
        </Col>

        { collapsed !== false && (
          <Col className="da-mt-8">
            <Link
              to="/"
              onClick={ onClose }
            >
              <img className="da-logo" src={ webDataObj ? webDataObj.email_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webDataObj.email_logo : "/noimage.jpg" : "/noimage.jpg" } alt="logo" />
            </Link>
          </Col>
        ) }
      </Row>
      <MainMenu />
    </Sider >
  );
}

const MenuItem = (each, i, k, uri = '/') => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/")
  let currenUrl = splitLocation[splitLocation.length - 1];

  let currentMenu = each.uri.split('/')[each.uri.split('/').length - 1];
  let concat = `${k}-${i}`;
  if (!each.children || each.children === null) {
    each.children = [];
  }

  if (!hasPermission(each.permissionKey, all_permissions)) {
    return <></>;
  }

  // implement has children

  if (each.children.length) {
    // if(each.uri === currenUrl){
    //   localStorage.setRemove('openMenuKey')
    //   localStorage.setItem('openMenuKey', concat)
    // }
    return (
      <Menu.SubMenu key={ concat } title={ each.title } icon={ each.icon } >
        { each.children.map((child, j) => MenuItem(child, j, concat, each.uri)) }
      </Menu.SubMenu>
    );
  }
  return (
    <Menu.Item key={ concat } icon={ each.icon } ><span><Link style={ { color: currentMenu === currenUrl ? crmStyle.bg_color : '' } } to={ uri + each.uri }>{ each.title }</Link></span></Menu.Item>
  );
}