import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderComponent, BodyComponent, TableComponent, ActionComponent, CreateButton, ButtonComponent } from "@comps/components";
import { makeRequest, notify, removeById, makeRequestStateless } from "@utils/helpers";
import { getBookings, getPastBookingsRequest, deleteBooking, checkoutBookingRequest, getAllClientsRequest } from "./requests";
import { PlusOutlined, ContainerOutlined, PoundCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col, Select } from "antd";
import AddNewPet from "./components/AddNewPet";
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import DatePicker from "react-multi-date-picker"
import StripePay from './../../paymentManagement/StripePay';
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Bookings",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      }
    ]
  }
}

const IndexBooking = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [clients, setClients] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc',
    filters
  });

  const [childComponent, setChildComponent] = useState(null);

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'client_name',
      title: 'Client Name',
      dataIndex: 'client_name',
      sorter: true,
    },
    {
      key: 'pet_name',
      title: 'Pet Name',
      dataIndex: 'pet_name',
      sorter: true,
    },
    {
      key: 'booking_type_name',
      title: 'Animal Boarding',
      dataIndex: 'booking_type_name',
      sorter: false,
    },
    {
      key: 'start_date',
      title: 'Day In',
      sorter: false,
      render: (each) => moment(each.start_date).format('DD/MM/YYYY')
    },
    {
      key: 'end_date',
      title: 'Day out',
      sorter: false,
      render: (each) => each.end_date ? moment(each.end_date).format('DD/MM/YYYY') : moment(each.start_date).format('DD/MM/YYYY')
    },
    {
      key: 'total_cats',
      title: 'Cats',
      sorter: false,
      render: (each) => <><Button onClick={ () => showPets(each.id) }>{ each.total_cats }</Button></>
    },
    {
      key: 'total_dogs',
      title: 'Dogs',
      sorter: false,
      render: (each) => <><Button onClick={ () => showPets(each.id) }>{ each.total_dogs }</Button></>
    },
    {
      key: "checkout",
      title: 'Checkout',
      render: (record) =>
        record.booking_checkout == null ?
          <Tooltip title="Checkout Booking" ><Button onClick={ () => checkOutBooking(record) } className="da-px-10 da-my-0" style={ { fontSize: 20, color: 'red' } } type="link" size="middle"><Icon icon="majesticons:door-exit-line" /></Button>
          </Tooltip> : moment(record.booking_checkout).format('DD/MM/YYYY')
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => ActionComponentEx(record)
    },
  ];

  const checkOutBooking = (record) => {
    makeRequest(setLoader, checkoutBookingRequest, record.id, onCheckOutSuccess, onCheckOutError);
  }

  const onCheckOutSuccess = (data) => {
    notify('success', 'Booking checked out successfully');
    getAllBookings();
  }

  const onCheckOutError = (data, res) => {
    notify('Error', "Please check out all pets first");
  }

  const ActionComponentEx = (record) => {
    return (
      <>
        <Tooltip title="Add New Pet" ><Button onClick={ () => onaddNewPet(record) } className="da-px-10 da-my-0" type="link" size="middle"><PlusOutlined className="icon-style" /></Button></Tooltip>
        <Tooltip title="View Invoice" ><Button onClick={ () => onViewInvoice(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: '#50a5f1', fontSize: '18px' } }><Icon icon="uil-invoice" /></Button></Tooltip>
        <Tooltip title="Pet Care Plan" ><Button onClick={ () => onViewCarePlan(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><ContainerOutlined /></Button></Tooltip>
        <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } >
        </ActionComponent>
        { record && !record.deposit_paid ?
          <Tooltip title="Pay Now" ><Button onClick={ () => onPayment(record) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><PoundCircleOutlined /></Button></Tooltip> : null }
      </>
    );
  }


  useEffect(() => {
    if (!filters) {
      getAllBookings();
      getAllClients();
    }
    // eslint-disable-next-line
  }, [pagination, filters]);

  const getAllBookings = () => {
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters
    };
    makeRequest(setLoader, getBookings, payload, onSuccess, null);
  }

  const onaddNewPet = (each) => {
    setChildComponent(<AddNewPet data={ each } onAddedSuccess={ onAddedSuccess } />);
  }

  const showPets = (id) => {
    history.push(`/booking-management/bookings/pets/${id}`);
  }

  const onViewInvoice = (each) => {
    history.push(`/invoice/${each.invoice}`);
  }

  const onViewCarePlan = (each) => {
    history.push(`/bookingcareplan/${each.id}`);
  }

  const onPayment = (each) => {
    setChildComponent(<StripePay data={ each } onCreated={ onCreated } />);
  }

  const onCreated = (response) => {
    setChildComponent(null);
    getAllBookings();
  }

  const onAddedSuccess = (response) => {
    if (response) {
      setChildComponent(null);
      getAllBookings();
      notify('success', 'Pet added successfully');
    } else {
      setChildComponent(null);
    }
  }

  const onSuccess = (response) => {
    setTotalRecords(response.recordsTotal);
    setDataSource(response.data);
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'descend' ? 'asc' : 'desc',
      filters
    };
    setPagination(payload);
  }

  // Create component modal
  const onCreate = () => {
    history.push(`/booking-management/bookings/create`);
  }

  const onEdit = (record) => {
    history.push(`/booking-management/bookings/edit/${record.id}`);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteBooking, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Booking", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  const bookingByDate = (date) => {
    date = moment.unix(date.unix).format('YYYY-MM-DD');
    setFilters({ start_date: date })
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: 200,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters: {
        start_date: date
      }
    };
    makeRequest(setLoader, getBookings, payload, onFilterSuccess, null);
  }

  const onFilterSuccess = (response) => {
    setTotalRecords(response.data.length);
    setDataSource(response.data);
  }

  const getAllClients = () => {
    makeRequestStateless(getAllClientsRequest, null, onGetAllClientsSuccess, Function);
  }

  const onGetAllClientsSuccess = (response) => {
    setClients(response);
  }

  const bookingByClient = (value) => {
    setFilters({ client_id: value })
    let payload = {
      start: (pagination.current - 1) * pagination.pageSize,
      length: 500,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,
      filters: {
        client_id: value
      }
    };
    makeRequest(setLoader, getBookings, payload, onFilterSuccess, null);
  }

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
        <CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } />
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32 da-mt-32">
          <Col xs={ 24 } lg={ 6 }>
            <Select
              style={ { width: '100%' } }
              showSearch
              onChange={ bookingByClient }
              filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
              placeholder="Booking By Client"
              options={ clients }
            />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <DatePicker
              format={ "DD/MM/YYYY" }
              onChange={ bookingByDate }
              // minDate={ moment().toDate() }
              placeholder="Select date"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
            />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <ButtonComponent onClick={ () => history.push("/booking-management/bookings/past") } type="primary" style={ { background: crmStyle.bg_color, border: crmStyle.border_color } }>Past Bookings</ButtonComponent>
          </Col>
        </Row>
        <TableComponent loader={ loader ? true : false } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexBooking;