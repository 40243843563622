import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton, } from "@comps/components";
import { Row, Col,Switch } from "antd";
import CreateLimitByRang from "./components/CreateLimitByRang";
import { makeRequest, removeById, notify, replaceById } from '@utils/helpers';
import { getLimitByRangRequest, deleteLimitByRang, changeModuleStatus, getModuleStatusReq } from './requests';
import EditLimitByRang from './components/EditLimitByRang';
import { useSelector } from 'react-redux';

const IndexLimitByRang = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [moduleStatus, setModuleStatus] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
      
    },
    {
      key: 'name',
      title: 'Booking Type Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'start_date',
      title: 'Start Date',
      dataIndex: 'start_date',
      sorter: true,
    },
    {
      key: 'end_date',
      title: 'End Date',
      dataIndex: 'end_date',
      sorter: true,
    },
    {
      key: 'limit',
      title: 'Days Limit',
      dataIndex: 'limit',
      sorter: true,
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } />
    },
  ];

  useEffect(() => {
    getData();
    getModuleStatus();
  }, [pagination]);

  const getData = () => {
    makeRequest(setLoader, getLimitByRangRequest, pagination, onSuccess, onError);
  }

  const onSuccess = (response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateLimitByRang crmStyle={ crmStyle } onCreated={ onCreated } />);
  }

  const onCreated = (each) => {
    if (each) {
      setDataSource([each.object, ...dataSource]);
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }


  const onEdit = (record) => {
    setChildComponent(<EditLimitByRang crmStyle={ crmStyle } id={ record.id } onUpdated={ onUpdated } />);
  }

  const onUpdated = (each) => {
    if (each) {
      setDataSource(replaceById(dataSource, each.object));
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteLimitByRang, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify(msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const onChangeModuleStatus = (checked) => {
    if(checked===true){
      checked = 1;
    }else{
      checked = 0;
    }
    makeRequest(setLoader, changeModuleStatus, checked, onStatusSuccess, onError);
  }

  const onStatusSuccess = (response, msg) => {
    notify(msg.msg);
    setModuleStatus(response.is_active==="1"?true:false);
  }

  const getModuleStatus = () => {
    makeRequest(setLoader, getModuleStatusReq, {}, onGetStatusSuccess, onError);
  }

  const onGetStatusSuccess = (response, msg) => {
    setModuleStatus(response.is_active==="1"?true:false);
  }

  return (
    <>
      { childComponent }
      <style>{`
      .ant-switch-checked {
        background-color: ${crmStyle.bg_color};
      }
      `}</style>
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 4 }><h4>Booking Limit By Range</h4> </Col>
              <Col span={ 8 }><Switch checked={moduleStatus} onChange={onChangeModuleStatus}/> </Col>
              <Col span={ 12 } className="da-text-right"><CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } text="Add Rang " /></Col>
            </Row>
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </div>
    </>
  );
}

export default IndexLimitByRang;