import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Menu, Dropdown, Col, Avatar } from "antd";
import { User, Logout } from "react-iconly";
import { logout as logoutAction } from "@mods/userManagement/auth/authSlice";
import { DownOutlined } from "@ant-design/icons";
import noimage from "@assets/img/noimage.jpg";

export default function HeaderUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.authUser);

  const logout = () => {
    dispatch({ type: logoutAction.type });
    window.location.href = "/";
  }

  const onProfile = () => {
    history.push(`/client-management/clients/view/${user.id}`);
  }

  const menu = (
    <Menu>
      {/* <Menu.Item
        onClick={ onProfile }
        key="user"
        icon={
          <User
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={ 16 }
          />
        }
      >
        Profile
      </Menu.Item> */}

      <Menu.Item
        key="logout"
        onClick={ logout }
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={ 16 }
          />
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={ menu } trigger={ ['click'] } >
      <Col className="da-d-flex-center" >
        <Avatar className="da-mr-6" src={ !user.profile_url ? noimage : user.profile_url } size={ 50 } />
        <DownOutlined />
      </Col>
    </Dropdown>
  );
};
