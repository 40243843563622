import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton } from "@comps/components";
import { Row, Col } from "antd";
import CreateBookingType from "./components/CreateBookingType";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getBookingTypeRequest, deleteBookingType } from './requests';
import EditBookingType from './components/EditBookingType';
import { useSelector } from 'react-redux';

const IndexBookingType = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'name',
      title: 'Pet Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      key: 'range',
      title: 'Date Type',
      dataIndex: 'is_range',
      sorter: true,
      render: (record) => record ? 'Range' : 'Multiple'
    },
    {
      key: 'deposit',
      title: 'Deposit',
      dataIndex: 'deposit',
      sorter: true,
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } />
    },
  ];

  useEffect(() => {
    makeRequest(setLoader, getBookingTypeRequest, pagination, onSuccess, onError);
  }, [pagination]);

  const onSuccess = (response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  // Create component modal
  const onCreate = () => {
    setChildComponent(<CreateBookingType crmStyle={ crmStyle } onCreated={ onCreated } />);
  }

  const onCreated = (each) => {
    if (each) {
      setDataSource([each.object, ...dataSource]);
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }


  const onEdit = (record) => {
    setChildComponent(<EditBookingType crmStyle={ crmStyle } id={ record.id } onUpdated={ onUpdated } />);
  }

  const onUpdated = (each) => {
    if (each) {
      setDataSource([each.object, ...dataSource]);
      setChildComponent(null);
    } else {
      setChildComponent(null);
    }
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deleteBookingType, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Booking Type", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }




  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }


  return (
    <>
      { childComponent }
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>Booking Types</h4></Col>
              <Col span={ 12 } className="da-text-right"><CreateButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } text="Add Booking Type " /></Col>
            </Row>
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </div>
    </>
  );
}

export default IndexBookingType;