// Layout
import BaseHeader from "./layout/BaseHeader";
import BaseBody from "./layout/BaseBody";
import BaseTable from "./layout/BaseTable";
import BaseAction from "./layout/BaseActions";
import BaseModal from "./layout/BaseModal";
import BaseTab from "./layout/BaseTab";
import BaseGoogleMap from "./layout/BaseGoogleMap";
import BaseTree from "./layout/BaseTree";
import BaseEditor from "./layout/BaseEditor";
// Filters
import BaseFilter from "./filters/BaseFilter";
// Buttons
import BaseButton from "./buttons/BaseButton";
import BaseCreate from "./buttons/BaseCreate";
import BaseUpload from "./buttons/BaseUpload";
import BaseCancel from "./buttons/BaseCancel";
import BaseSave from "./buttons/BaseSave";

// Layout
export const HeaderComponent = BaseHeader;
export const BodyComponent = BaseBody;
export const TableComponent = BaseTable;
export const Editor = BaseEditor;
export const ActionComponent = BaseAction;
export const ModalComponent = BaseModal;
export const TabComponent = BaseTab;
export const TreeComponent = BaseTree;
// Filters
export const FilterComponent = BaseFilter;
// Buttons
export const ButtonComponent = BaseButton;
export const CreateButton = BaseCreate;
export const UploadButton = BaseUpload;
export const CancelButton = BaseCancel;
export const SaveButton = BaseSave;
//Components
export const GoogleMap = BaseGoogleMap;
