import React, { useState, useEffect } from "react";
import { TableComponent, HeaderComponent, BodyComponent, ButtonComponent } from "@comps/components";
import { makeRequest, getErrorProps } from '@utils/helpers';
import { Row, Col, Form, Input, Select, } from "antd";
import DatePicker from "react-multi-date-picker"
import { getCheckerDependencies, availabilityCheckRequest } from './requests';
import { useSelector } from 'react-redux';
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Availability Checker",
    breadcrumb: [
      {
        name: "Availability",
        path: "/availability"
      }
    ]
  }
}

const formName = "availabilityCheckerForm";

const IndexAvailabilityChecker = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [errors, setErrors] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(0);
  const [searching, setSearching] = useState(false);
  const [childComponent, setChildComponent] = useState(null);
  const [deps, setDeps] = useState({
    petTypes: [],
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      sorter: false,
    },
    {
      key: 'available',
      title: 'Availability',
      dataIndex: 'available',
      sorter: false,
    },
  ];

  useEffect(() => {
    getSelectFieldsData();
  }, []);

  const getSelectFieldsData = () => {
    makeRequest(setLoader, getCheckerDependencies, null, onDependencySuccess, null);
  }

  const onDependencySuccess = (data) => {
    setDeps({
      ...deps,
      petTypes: data.petTypes,
    });
  }

  const onFinish = (data) => {
    let payload = data
    payload.start_date = moment.unix(data.start_date.unix).format('YYYY-MM-DD');
    payload.end_date = moment.unix(data.end_date.unix).format('YYYY-MM-DD');
    let bookingDates = [];
    let currentDate = moment(payload.start_date);
    let endDate = moment(payload.end_date);
    while (currentDate <= endDate) {
      bookingDates.push(currentDate.format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(1, 'days');
    }
    payload.booking_dates = bookingDates.sort(function (a, b) {
      a = a.split('/').reverse().join('');
      b = b.split('/').reverse().join('');
      return a > b ? 1 : a < b ? -1 : 0;
    });
    makeRequest(setLoader, availabilityCheckRequest, payload, onSuccess, onError);
  }


  const validateNumber = (rule, value, callback) => {
    if (value && value >= 40) {
      callback();
    } else {
      callback('Please enter a number greater than or equal to 40');
    }
  };

  const onSuccess = (data, res) => {
    setDataSource(data);
  }


  const onError = (error, msg) => {
    //
  }

  const onPetTypeChange = (value) => {
    let limit = deps.petTypes.find((item) => item.value === value).limit;
    setLimit(limit);
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const rules = {
    pet_type_id: [
      { required: true, message: 'Please select type', },
    ],
    kennels: [
      { required: true, message: 'Please enter no of kennels', },
      {
        validator: (_, value) =>
          value <= limit ? Promise.resolve() : Promise.reject('Booking limit exceeded limit is ' + limit + ''),
      },
    ],
    start_date: [
      { required: true, message: 'Please select start date', },
    ],
    end_date: [
      { required: true, message: 'Please select end date', },
    ],
  };



  return (
    <>
      { childComponent }
      <style>{ `.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {display:none;}` }</style>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <Row className="da-mb-64 da-mt-32">
          <Col span={ 24 }>
            <Form
              layout="inline"
              name={ formName }
              onFinish={ onFinish }
            >
              <Form.Item name="pet_type_id" label="Pet Type" rules={ rules.pet_type_id } className="da-mb-16 width-100"
                { ...getErrorProps(errors['pet_type_id']) }
              >
                <Select
                  onChange={ onPetTypeChange }
                  showSearch
                  filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                  placeholder="Pet type"
                  options={ deps.petTypes }
                />
              </Form.Item>
              <Form.Item name="kennels" label="Number of kennels/Cabins" rules={ rules.kennels } className="da-mb-16  width-100"
                { ...getErrorProps(errors['kennels']) }
              >
                <Input type="number" placeholder="Number of kennels/Cabins" />
              </Form.Item>
              <Form.Item name="start_date" label="Start Date" rules={ rules.start_date } className="da-mb-16  width-100"
                { ...getErrorProps(errors['start_date']) }
              >
                <DatePicker
                  format={ "DD/MM/YYYY" }
                  minDate={ moment().toDate() }
                  placeholder="Select start date"
                  containerStyle={ {
                    width: "100%"
                  } }
                  style={ { width: '100%', height: '40px' } }
                />
              </Form.Item>
              <Form.Item name="end_date" label="End Date" rules={ rules.end_date } className="da-mb-16 width-100"
                { ...getErrorProps(errors['end_date']) }
              >
                <DatePicker
                  format={ "DD/MM/YYYY" }
                  minDate={ moment().toDate() }
                  placeholder="Select end date"
                  containerStyle={ {
                    width: "100%"
                  } }
                  style={ { width: '100%', height: '40px' } }
                />
              </Form.Item>
              <Form.Item className="width-100">
                <ButtonComponent style={ { background: crmStyle.bg_color, border: crmStyle.border_color, color: 'white' } } form={ formName } key="create_button" htmlType="submit" state={ loader }>Check Availability</ButtonComponent>

              </Form.Item>
            </Form>
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );

}




export default IndexAvailabilityChecker;