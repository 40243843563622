import { BodyComponent, ButtonComponent, TableComponent } from "@comps/components";
import { Row, Col, Card, Tooltip, Calendar, Popconfirm } from 'antd';
import { WarningOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { getDashboardData, getBookingPetCheckinRequest, getBookingPetCheckoutRequest } from '../requests';
import { makeRequest, notify } from '@utils/helpers';
const moment = require('moment');

const Dashboard = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [petTypes, setPetTypes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let date = moment().format('YYYY-MM');
    makeRequest(setLoader, getDashboardData, date, onDatSuccess, onDatError);
  }

  const onDatSuccess = (data, res) => {
    setData(data);
    setPetTypes(data.pet_types);
  }

  const onDatError = (error) => {
    console.log(error);
  }

  const handlePanelChange = (value, mode) => {
    makeRequest(setLoader, getDashboardData, value.format('YYYY-MM'), onDatSuccess, onDatError);
    // Call your function here with the new month or year
  };


  const onCheckIn = (id) => {
    makeRequest(setLoader, getBookingPetCheckinRequest, id, onCheckInSuccess, onError);
  }

  const onCheckInSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onError = (error) => {
    notify("Pet", error.msg);
  }

  const onCheckOut = (id) => {
    makeRequest(setLoader, getBookingPetCheckoutRequest, id, onCheckOutSuccess, onError);
  }

  const onCheckOutSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onPetDetail = (date, type) => {
    history.push(`/pet-details/${date}/${type}`);
  }

  const dateCellRender = (value) => {
    let date = moment(value).format('YYYY-MM-DD');

    return (
      <ul className="events">
        { petTypes.map((item) => date === item.date ?

          <li key={ item.name }>
            <div onClick={ () => onPetDetail(date, item.id) } style={
              {
                background: item.bg_color,
                color: item.text_color,
                border: item.border_color,
                marginBottom: 5,
                paddingLeft: 10,
              }
            }>{ item.name } { "(" + item.total_booking + ")" }</div>
          </li>
          : null
        ) }
      </ul>
    );
  };

  const ariveColumns = [
    {
      key: 'date',
      title: 'A-D',
      sorter: false,
      render: (record) => (
        <div className="da-text-center">
          <div><strong>{ moment(record.expected_checked_in).format('DD') }-{ moment(record.expected_checked_out).format('DD') }</strong></div>
        </div>
      )
    },
    {
      key: 'Type',
      title: 'Type',
      sorter: false,
      render: (record) => record.booking.booking_type.name
    },
    {
      key: 'Customer',
      title: 'Customer',
      sorter: false,
      render: (record) => record.booking.client.name
    },
    {
      key: 'Pet',
      title: 'Pet Type',
      sorter: false,
      render: (record) => record.pet.pet_type.name
    },
    {
      key: 'Pet',
      title: 'Pet Name',
      sorter: false,
      render: (record) => record.pet.name
    },
    {
      key: '',
      title: '',
      sorter: false,
      render: (item) => (
        <>
          {
            item.checked_in === null ?
              <Popconfirm title={ `Are you sure you want to Checkin ${item.pet.name}?` } icon={ <WarningOutlined /> } onConfirm={ () => onCheckIn(item.id) }>
                <Tooltip title="Check-In Pet" ><ButtonComponent className="da-px-10 da-my-0" style={ { fontSize: 20, color: crmStyle.bg_color } } type="link" size="middle"><Icon icon="majesticons:door-enter-line" /></ButtonComponent>
                </Tooltip>
              </Popconfirm>
              : null
          }
          <Tooltip title="View Pet Booking" >
            <ButtonComponent type="link" className="da-ml-8 da-text-light" style={ { color: crmStyle.bg_color, fontSize: 20 } } size="small" onClick={ () => history.push(`/booking-management/bookings/pets/${item.booking.id}`) }><Icon icon="ic:outline-remove-red-eye" /></ButtonComponent></Tooltip>

          <Tooltip title={ item.booking.deposit_paid ? 'Deposit already paid' : 'Deposit unpaid' } >
            <ButtonComponent type="link" className="da-ml-8 da-text-light" style={ { color: item.booking.deposit_paid ? crmStyle.bg_color : 'red', fontSize: 20 } } size="small"><Icon icon="majesticons:pound-circle-line" /></ButtonComponent></Tooltip>

        </>
      )
    }
  ];

  const departColumns = [
    {
      key: 'date',
      title: 'A-D',
      sorter: false,
      render: (record) => (
        <div className="da-text-center">
          <div><strong>{ moment(record.expected_checked_in).format('DD') }-{ moment(record.expected_checked_out).format('DD') }</strong></div>
        </div>
      )
    },
    {
      key: 'Type',
      title: 'Type',
      sorter: false,
      render: (record) => record.booking.booking_type.name
    },
    {
      key: 'Customer',
      title: 'Customer',
      sorter: false,
      render: (record) => record.booking.client.name
    },
    {
      key: 'Pet',
      title: 'Pet Type',
      sorter: false,
      render: (record) => record.pet.pet_type.name
    },
    {
      key: 'Pet',
      title: 'Pet Name',
      sorter: false,
      render: (record) => record.pet.name
    },
    {
      key: '',
      title: '',
      sorter: false,
      render: (item) => (
        <>
          { item.checked_out === null ?
            <Popconfirm title={ `Are you sure you want to checkout ${item.pet.name}?` } icon={ <WarningOutlined /> } onConfirm={ () => onCheckOut(item.id) }>
              <Tooltip title="Check-out Pet" ><ButtonComponent className="da-px-10 da-my-0" style={ { fontSize: 20, color: 'red' } } type="link" size="middle"><Icon icon="majesticons:door-exit-line" /></ButtonComponent>
              </Tooltip>
            </Popconfirm>
            : null }
          <Tooltip title="View Pet Booking" >
            <ButtonComponent type="link" className="da-ml-8 da-text-light" style={ { color: crmStyle.bg_color, fontSize: 20 } } size="small" onClick={ () => history.push(`/booking-management/bookings/pets/${item.booking.id}`) }><Icon icon="ic:outline-remove-red-eye" /></ButtonComponent></Tooltip>
        </>
      )
    }
  ];

  const gotoAttendance = () => {
    history.push('/pet-management/attendance');
  }

  return (
    <div className="mainbox">
      <style>{ `.events .ant-badge-status {border:none;background:none;}.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child { border-color:${crmStyle.border_color};color:${crmStyle.text_color}}}.ant-radio-button-wrapper-checked{border-right-color:${crmStyle.border_color};}.ant-radio-button-wrapper {color:${crmStyle.text_color}}.ant-radio-button-wrapper-checked{
        border-color:${crmStyle.border_color} !important;
      }.ant-picker-calendar-header .ant-picker-calendar-mode-switch {display:none}.ant-popover-buttons .ant-btn {
        border-color:${crmStyle.border_color};background:${crmStyle.bg_color};
      }` }</style>
      <h3>Analytics Overview</h3>
      <BodyComponent>
        <div className="da-mt-24">
          <Row justify="center" gutter={ 16 } >
            <Col onClick={ gotoAttendance } xs={ 24 } lg={ { span: 8, offset: 0 } } xl={ { span: 8, offset: 0 } } xxl={ { span: 8, offset: 0 } } className="da-text-center da-mb-12" style={ { padding: 8, cursor: 'pointer' } }>
              <Card style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } bordered={ true }>
                <h4>{ data.total_bookings }</h4>
                Checked In / Booking
              </Card>
            </Col>
            <Col xs={ 24 } lg={ { span: 7, offset: 0 } } xl={ { span: 8, offset: 0 } } xxl={ { span: 8, offset: 0 } } className="da-text-center da-mb-12" style={ { padding: 8 } }>
              <Card style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } bordered={ true }>
                <h4>{ data.total_pet_arriving }</h4>
                Pets Arriving Today
              </Card>
            </Col>
            <Col xs={ 24 } lg={ { span: 8, offset: 0 } } xl={ { span: 8, offset: 0 } } xxl={ { span: 8, offset: 0 } } className="da-text-center da-mb-12" style={ { padding: 8 } }>
              <Card style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } bordered={ true }>
                <h4>{ data.total_pet_departing }</h4>
                Pets Leaving Today
              </Card>
            </Col>
          </Row>
        </div>

        <div className="da-mt-64">
          <Row justify="center" gutter={ 8 }>
            <Col xs={ 24 } xl={ { span: 12 } } className="da-pr-16 ">
              <div className="da-mb-12  da-h-100 da-pt-24 da-pl-24 da-pb-24 arriveBox" >
                <h5>Pets Arriving Today</h5>
                <div style={ {
                  height: 410,
                  overflowY: 'scroll',
                } }>
                  <TableComponent loader={ loader } columns={ ariveColumns } dataSource={ data.total_pet_arriving_today } pagination={ false } />
                </div>
              </div>
            </Col>
            <Col xs={ 24 } xl={ { span: 12 } } className="arriveBox da-h-100 da-pt-24 da-pl-24">
              <h5>Pets Leaving Today</h5>
              <div style={ {
                height: 410,
                overflowY: 'scroll',
              } }>
                <TableComponent loader={ loader } columns={ departColumns } dataSource={ data.total_pet_departing_today } pagination={ false } />
              </div>
            </Col>
          </Row>
        </div>
        <div className="da-mt-64" style={ { overflow: 'scroll' } } >
          <div className="mobilecalender">
            <Calendar onPanelChange={ handlePanelChange } mode="month" fullscreen={ true } dateCellRender={ dateCellRender } />
          </div>
        </div>

      </BodyComponent>

    </div>
  );
}

export default Dashboard;
