import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { TabComponent, HeaderComponent } from "@comps/components";
import { makeRequest } from "@utils/helpers";
import { getClient } from "../requests";
import AboutClient from './view/AboutClient';
import { Row, Col } from 'antd';
import ClientNote from './view/ClientNote';
import BaseButton from '../../../wrappers/buttons/BaseButton';
import ClientPets from "./view/ClientPets";
import ClientBookings from './view/ClientBookings';
import noimage from '@assets/img/noimage.jpg';
import { useSelector } from 'react-redux';
import { notify } from '@utils/helpers';

const userData = JSON.parse(localStorage.getItem("user"));

const pageConfig = {
  headers: {
    title: "Client details",
    breadcrumb: [
      {
        name: "Clients",
        path: "/client-management/clients"
      },
      {
        name: "View Client",
        path: "/client-management/clients/view"
      }
    ]
  }
}

const ViewClient = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [data, setData] = useState({
    object: null,
    dependencies: []
  });



  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getClient, id, onSuccess, onError);
  }

  const onSuccess = (res) => {
    setData(res);
  }

  const goBack = () => {
    if (userData.role_id === 2) {
      history.push(`/`);
    } else {
      history.push(`/client-management/clients`);
    }
  }


  const tabs = [
    {
      title: "About Client",
      content: <AboutClient data={ data.object } dependencies={ data.dependencies } />
    },
    {
      disabled: userData.role_id === 2 ? true : false,
      title: "Note",
      content: <ClientNote crmStyle={ crmStyle } data={ data.object } dependencies={ data.dependencies } />
    },
    {
      title: "Pets",
      content: <ClientPets crmStyle={ crmStyle } />
    },
    {
      title: "Bookings",
      content: <ClientBookings crmStyle={ crmStyle } />
    },
  ]

  const onError = (res) => {
    notify("Error", res.msg, "error")
  }

  if (data.object == null) return (<div></div>);

  return (
    <>
      <style>{ `.ant-btn:hover {border-color:${crmStyle.bg_color} !important;color:${crmStyle.bg_color} !important;}.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {display:none !important;}
`}</style>
      <Row gutter={ 16 }>
        <Col span={ 24 }>
          <HeaderComponent headers={ pageConfig.headers }>
            <BaseButton onClick={ goBack }>Go Back</BaseButton>
          </HeaderComponent>
        </Col>
        <Col className="da-p-12 da-pl-24 " xs={ 24 } lg={ 7 }>
          <div className="da-text-center borderbottom da-pb-24">
            <img className="avatar" src={ data.object.clientInfo && data.object.clientInfo.image ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.object.clientInfo.image : noimage } alt="client" />
            <h5 className="da-mt-12">{ data.object.name }</h5>
            <strong>{ data.object.is_active === 1 ? "Active" : "inActive" }</strong>
          </div>
        </Col>
        <Col xs={ 24 } lg={ 15 }>
          <TabComponent headers={ false } tabs={ tabs } loader={ loader }></TabComponent>
        </Col>
      </Row>
    </>
  );
}

export default ViewClient;
