import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { TableComponent, HeaderComponent, BodyComponent } from "@comps/components";
import { PrinterOutlined } from '@ant-design/icons';
import { useParams, useHistory } from "react-router-dom";
import { debounce } from 'lodash';
import { getTransactionReq } from './requests';

const pageConfig = {
  headers: {
    title: "Transactions",
    breadcrumb: [
      {
        name: "Transactions",
        path: "/transactions"
      }
    ]
  }
}

const InvoiceIndex = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState(0);
  const [orgData, setOrgData] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      sorter: false,
      width: '100px',

    },
    {
      key: 'user_name',
      title: 'User Name',
      dataIndex: 'user_name',
      sorter: false,
    },
    {
      key: 'user_email',
      title: 'User Email',
      dataIndex: 'user_email',
      sorter: false,
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      sorter: false,
    },
    {
      key: 'currency',
      title: 'Currency',
      dataIndex: 'currency',
      sorter: false,
    },
    {
      key: 'transaction_id',
      title: 'Transaction ID',
      dataIndex: 'transaction_id',
      sorter: false,
    },
    {
      key: 'card_last_four',
      title: 'Card Last Four Digits',
      dataIndex: 'card_last_four',
      sorter: false,
    },
    {
      key: 'card_exp_month',
      title: 'Card Exp Month',
      dataIndex: 'card_exp_month',
      sorter: false,
    },
    {
      key: 'card_exp_year',
      title: 'Card Exp Year',
      dataIndex: 'card_exp_year',
      sorter: false,
    },
  ];

  useEffect(() => {
    getTransactions();
  }, []);

  const getTransactions = () => {
    setLoader(true);
    makeRequest(setLoader, getTransactionReq, null, OnDataSuccess, OnDataError);

  }

  const OnDataSuccess = (data) => {
    setDataSource(data.data);
    setOrgData(data.data);
    setTotalRecords(data.total);
  }

  const OnDataError = (error) => {
    notify('error', error.message);
  }

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  //filters
  const onSearchByUserName = (e) => {
    if (e) {
      let filteredData = orgData.filter((item) => {
        return item.user_name.toLowerCase().indexOf(e.toLowerCase()) !== -1;
      });
      setDataSource(filteredData);
    } else {
      setDataSource(orgData);
    }
  }

  const debouncedGetByName = debounce((e) => {
    onSearchByUserName(e.target.value);
  }, 300);

  const debouncedGetByEmail = debounce((e) => {
    onSearchByUserEmail(e.target.value);
  }, 300);

  const debouncedGetByTransId = debounce((e) => {
    onSearchByTransID(e.target.value);
  }, 300);

  const onSearchByUserEmail = (e) => {
    if (e) {
      let filteredData = orgData.filter((item) => {
        return item.user_email.toLowerCase().indexOf(e.toLowerCase()) !== -1;
      });
      setDataSource(filteredData);
    } else {
      setDataSource(orgData);
    }
  }

  const onSearchByTransID = (e) => {
    if (e) {
      let filteredData = orgData.filter((item) => {
        return item.transaction_id.toLowerCase().indexOf(e.toLowerCase()) !== -1;
      });
      setDataSource(filteredData);
    } else {
      setDataSource(orgData);
    }
  }



  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32 da-mt-32">
          <Col xs={ 24 } lg={ 6 }>
            <Input onChange={ debouncedGetByName } placeholder="Search by user name" />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <Input onChange={ debouncedGetByEmail } placeholder="Search by user email" />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <Input onChange={ debouncedGetByTransId } placeholder="Search by transaction id" />
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default InvoiceIndex;