import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { makeRequest } from '@utils/helpers';
import { useParams } from "react-router-dom";
import { getPetCarePlan } from "../requests";
const moment = require('moment');
const webData = localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {};

const PetCarePlan = (props) => {
  const { id } = useParams();
  const [data, setData] = useState();


  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(Function, getPetCarePlan, id, onSuccess, onError);
  }

  const onSuccess = (data) => {
    setData(data);
  }

  const onError = (error) => {

  }


  if (!data) return null;


  return (
    <>
      <div>
        <Row gutter={ 16 } className="da-text-center">
          <Col span={ 24 }><h2>Pet Care Plan</h2></Col>
        </Row>
        <Row gutter={ 16 } className="da-p-0 da-m-0">
          <Col span={ 24 } className="daycareplan">
            <table border="1" width="100%">
              <tr>
                <td><strong>Pet Name</strong></td>
                <td>
                  { data.name }
                </td>
                <td><strong>Pet DOB</strong></td>
                <td>
                  { moment(data.dob).format('DD/MM/YYYY') }

                </td>
              </tr>
              <tr>
                <td><strong>Pet Breed</strong></td>
                <td>
                  { data.breed }
                </td>
                <td><strong></strong></td>
                <td><strong></strong></td>
              </tr>
              <tr>
                <td><strong>Owner</strong></td>
                <td>
                  { data.client.name }
                </td>
                <td><strong>Address </strong></td>
                <td rowspan="2">
                  { data.client.clientinfo ? data.client.clientinfo.address_line1 : null }<br />
                  { data.client.clientinfo ? data.client.clientinfo.address_line2 : null }</td>
              </tr>
              <tr>
                <td><strong>Contact</strong></td>
                <td>

                  Telephone: { data.client.clientinfo ? data.client.clientinfo.phone : null }<br />
                  Mobile: { data.client.clientinfo ? data.client.clientinfo.mobile : null }<br />
                  Email: { data.client.email }
                </td>
              </tr>
              <tr>
                <td><strong>Vaccination Date Given</strong></td>
                <td>
                  { moment(data.vaccination_date).format('DD/MM/YYYY') }
                </td>
                <td><strong>Kennel Cough Date Given</strong></td>
                <td>
                  { moment(data.kennel_cough_date).format('DD/MM/YYYY') }
                </td>
              </tr>
              <tr>
                <td><strong>Netured / Spayed</strong></td>
                <td>
                  { data.neutered_spayed ? <strong>Yes</strong> : <strong>No</strong> }
                </td>
                <td><strong>Flee / Tick</strong></td>
                <td>
                  { data.flee_tick_treatment ? <strong>Yes</strong> : <strong>No</strong> }
                </td>
              </tr>
              <tr>
                <td><strong>Wormed</strong></td>
                <td>
                  { data.wormed ? <strong>Yes</strong> : <strong>No</strong> }
                </td>
                <td><strong>MicroChip # </strong></td>
                <td>
                  { data.microchip_no }
                </td>
              </tr>
              <tr>
                <td><strong>Meal Times</strong></td>
                <td colspan="3">
                  { data.petdiets && data.petdiets.map((diet, index) => (
                    <div key={ index }>
                      <strong>{ diet.title }</strong><br />
                      { diet.time }<br />
                      { diet.description }<br /><br />
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <td><strong>Medication Types & Treatment Times</strong></td>
                <td colspan="3">
                  { data.petmedication && data.petmedication.map((medication, index) => (
                    <div key={ index }>
                      <strong>{ medication.title }</strong><br />
                      { medication.time }<br />
                      { medication.description }<br /><br />
                    </div>
                  )) }
                </td>
              </tr>
              <tr>
                <td><strong>Vets Details</strong></td>
                <td colspan="2">
                  { data.vet_name }
                </td>
                <td>
                  { data.vet_address_line1 }<br />
                  { data.vet_address_line2 }<br />
                  { data.vet_phone }<br />
                  { data.vet_emergency_phone }<br />

                </td>
              </tr>
              <tr>
                <td><strong>Emergency Contacts</strong></td>
                <td>
                  { data.vet_name }
                </td>
                <td>
                  { data.vet_address_line1 }<br />
                  { data.vet_address_line2 }<br />
                  { data.vet_phone }<br />
                  { data.vet_emergency_phone }<br />
                </td>
              </tr>
              <tr>
                <td colspan="4">( { data.own_vet ? <strong>Yes</strong> : <strong>No</strong> } )  { webData ? webData.pet_term_text : "You Accept Booking System Terms" }</td>
              </tr>
            </table>
            <Row gutter={ 16 } className="da-mt-16">
              <Col span={ 12 }><h3>Name</h3></Col>
              <Col span={ 12 }><h3>Signed</h3></Col>
            </Row>
            <Row gutter={ 16 } className="da-mt-16">
              <Col span={ 24 }><h3>Date</h3></Col>
            </Row>

          </Col>
        </Row>

      </div>
    </>
  );
}

export default PetCarePlan;