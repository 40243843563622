import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Col, Form, Input, Row } from "antd";
import axios from "axios";
import noimage from '@assets/img/noimage.jpg';
import { getErrorProps, makeRequest, notify } from "@utils/helpers";
import { ButtonComponent } from "@comps/components";
import { forget } from "../requests";


const ForgetPassword = () => {
  const [webInData, setWebInData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);


  React.useEffect(() => {
    let webData = localStorage.getItem('webData');
    setWebInData(JSON.parse(webData));
    if (!webData) {
      axios.get(process.env.REACT_APP_API_BASE_URL + '/get-initial-data').then((res) => {
        localStorage.setItem('webData', JSON.stringify(res.data.data));
        setWebInData(res.data.data);
      })
    }

  }, []);

  const onFinish = (payload) => {
    makeRequest(setLoader, forget, payload, onSuccess, onError);
  }

  const onSuccess = (data) => {
    notify('success', 'Password reset link has been sent to your email address. Please check your email.');
  }

  const onError = (data) => {
    notify('error', data.msg);
  }

  const LoginForm = (props) => (
    <>
      <h3 className="da-mb-sm-0">Recover Password</h3>
      <p className="da-mt-sm-0 da-mt-8 da-text-color-black-60">
        We’ll e-mail you instructions on how to reset your password.
      </p>
      <Form
        layout="vertical"
        name="basic"
        initialValues={ { remember: true } }
        className="da-mt-sm-16 da-mt-32"
        onFinish={ onFinish }
      >
        <Form.Item name="email" rules={ rules.email } label="Email :" className="da-mb-16"
          { ...getErrorProps(errors['email']) }>
          <Input />
        </Form.Item>

        <Form.Item className="da-mt-16 da-mb-8">
          <ButtonComponent style={ { background: props.crmStyle.bg_color, borderColor: props.crmStyle.border_color } } block type="primary" htmlType="submit" state={ loader }>
            Reset Password
          </ButtonComponent>
        </Form.Item>
      </Form>
      <div className="da-mt-64 da-text-center">
        <Link
          className="da-button da-text-color-success-80"
          to="/"
        >
          Go Back? Login
        </Link>
      </div>
    </>
  );

  if (webInData === null) return (<div>Loading...</div>);

  return (
    <div className="loginpage" style={ {
      backgroundImage: `url('${webInData.login_background ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.login_background : "/img/loginbg.jpg"}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      minHeight: '1000px',
      overflow: 'hidden',
    } }>
      <Row gutter={ [32, 0] } >

        <Col lg={ 12 } span={ 24 } >
          <Col xxl={ 24 } xl={ 24 } lg={ 24 } md={ 24 } sm={ 24 } className="da-text-center da-px-sm-8 da-pt-24 da-pr-96">
            <img src={ webInData.letter_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.letter_logo : noimage } width={ 150 } alt="img" />
          </Col>
          <Row align="middle" justify="center">
            <Col
              xxl={ 24 }
              xl={ 24 }
              lg={ 24 }
              md={ 24 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pl-96"
            >
              <h2 style={ { paddingTop: 100 } } class="login-header-text">{ webInData.login_text }</h2>
            </Col>
          </Row>
        </Col>
        <Col lg={ 12 } span={ 24 } >
          <Row align="middle" justify="center">
            <Col
              xxl={ 11 }
              xl={ 15 }
              lg={ 20 }
              md={ 20 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pb-48"
            >
              <div className="loginbox">
                { <LoginForm crmStyle={ webInData.style } /> }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

}

export default ForgetPassword;

const rules = {
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "Please input your email!" },
  ],
};
