import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Button } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { TableComponent, ButtonComponent, ActionComponent } from "@comps/components";
import { PrinterOutlined } from '@ant-design/icons';
import { useParams, useHistory } from "react-router-dom";
import noimage from '@assets/img/noimage.jpg';
import { getSettings, getInvoice, deleteInvoiceAddonReq } from './requests';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import CreateInvoiceAddon from './CreateInvoiceAddon';
import UpdateInvoiceAddon from './UpdateInvoiceAddon';
const moment = require('moment');

const InvoiceIndex = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [allowAddional, setAllowAddional] = useState(false);
  const [siteData, setSiteData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceAddons, setInvoiceAddons] = useState([]);
  const [bookingDate, setBookingDate] = useState([]);
  const [dataSource, setDataSource] = useState(0);
  const [childComponent, setChildComponent] = useState(null);

  useEffect(() => {
    getData();
    getInvoiceData(id);
  }, []);

  const columns = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      sorter: false,
      width: '100px',
    },
    {
      key: 'pet_name',
      title: 'Pet Name',
      dataIndex: 'pet_name',
      sorter: false,
    },
    {
      key: 'pet_type_name',
      title: 'Pet Type',
      dataIndex: 'pet_type_name',
      sorter: false,
    },
    {
      key: 'price',
      title: 'Price',
      dataIndex: 'price',
      sorter: false,
      render: (price) => { return '£' + Number(price).toFixed(2); },
    },
    {
      key: 'days',
      title: 'Total Days',
      dataIndex: 'days',
      sorter: false,
    },
    {
      key: 'total',
      title: 'Total',
      dataIndex: 'total',
      sorter: false,
      render: (total) => { return '£' + Number(total).toFixed(2); },
    },
  ];

  const columnsAddons = [
    {
      key: 'id',
      title: '#',
      dataIndex: 'id',
      sorter: false,
      width: '100px',
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      sorter: false,
    },
    {
      key: 'amount',
      title: 'Amount',
      dataIndex: 'amount',
      sorter: false,
      render: (amount) => { return '£' + Number(amount).toFixed(2); },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'is_paid',
      render: (status) => {
        return status === 1 ? <div style={ { color: 'green' } }>Paid</div> : <div style={ { color: 'red' } }>Unpaid</div>;
      },
    },
    {
      key: 'action',
      title: '',
      render: (text, record) => (
        <div className="hide-in-print">
          <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } >
          </ActionComponent>
        </div>
      ),
    },
  ];

  const onEdit = (data) => {
    setChildComponent(<UpdateInvoiceAddon data={ data } onCreated={ onCreated } crmStyle={ crmStyle } />);
  }

  const onDelete = (data) => {
    makeRequest(setLoader, deleteInvoiceAddonReq, data.id, onDeleteSuccess, onDatError);
  }

  const onDeleteSuccess = (data, res) => {
    notify('success', res.msg);
    getData();
    getInvoiceData(id);
  }

  const getInvoiceData = (number) => {
    makeRequest(setLoader, getInvoice, number, onInvoiceDatSuccess, onDatError);
  }

  const onInvoiceDatSuccess = (data, res) => {
    setInvoiceData(data[0]);
    setDataSource(data[0].invoice_data);
    setInvoiceAddons(data[0].invoice_addons);
    setAllowAddional(data.allowAdditional);
    setBookingDate(data.bookingDates);
  }

  const getData = () => {
    makeRequest(setLoader, getSettings, {}, onDatSuccess, onDatError);
  }

  const onDatSuccess = (data, res) => {
    setSiteData(data);
  }

  const onDatError = (error) => {
    console.log(error);
  }


  const handlePrint = () => {
    window.print();
  };

  const onCreate = () => {
    setChildComponent(<CreateInvoiceAddon id={ invoiceData.id } onCreated={ onCreated } crmStyle={ crmStyle } />);
  }

  const onCreated = (data) => {
    setChildComponent(null);
    getData();
    getInvoiceData(id);
  }



  if (invoiceData.length === 0) {
    return null;
  }

  return (
    <>
      { childComponent }
      <div>
        <Row gutter={ 16 } className="hide-in-print">
          <Col span={ 12 }><h4>Invoice</h4></Col>
          <Col className="da-text-right" span={ 12 }></Col>
        </Row>
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mt-32 rightBox da-p-64">
            <Row gutter={ 16 }>
              <Col span={ 12 } className="da-pr-64 npm">
                <img src={ siteData.site_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + siteData.site_logo : noimage } alt="invoice_logo" style={ { width: '150px' } } />
              </Col>
              <Col span={ 12 } className="da-text-right da-pr-64"><h4>Invoice # <a href={ `/booking-management/bookings/edit/` + invoiceData.booking.id }> { invoiceData.invoice_no }</a> { invoiceData.booking.deposit_paid ? <div style={ { color: 'green' } }>Deposit Paid</div> : <div style={ { color: 'orange' } }>Unpaid</div> }</h4></Col>
            </Row>
            <Divider />
            <Row gutter={ 16 }>
              <Col span={ 12 } className="da-pr-64 npm">
                <strong>Billed To:</strong>
                <p>{ invoiceData.booking.client ? invoiceData.booking.client.name : null }</p>
                <p className="da-m-0 da-p-0">{ invoiceData.booking.client ? invoiceData.booking.client.clientinfo.phone : null }</p>
                <p className="da-m-0 da-p-0">{ invoiceData.booking.client ? invoiceData.booking.client.clientinfo.address_line1 : null }</p>
                <p className="da-m-0 da-p-0">{ invoiceData.booking.client ? invoiceData.booking.client.clientinfo.address_line2 : null }</p>
                <p className="da-m-0 da-p-0">{ invoiceData.booking.client ? invoiceData.booking.client.clientinfo.city : null }</p>
                <p className="da-m-0 da-p-0">Postal Code { invoiceData.booking.client ? invoiceData.booking.client.clientinfo.postal_code : null }</p>
              </Col>
              <Col span={ 12 } className="da-text-right da-pr-64 npm">
                <strong>Billed From:</strong>
                <p >{ siteData.title }</p>
                <p className="da-m-0 da-p-0">{ siteData.address }</p>
                <p className="da-m-0 da-p-0">{ siteData.telephone }</p>
                <p className="da-m-0 da-p-0 da-mb-6">{ siteData.email }</p>
                <strong>Invoice Date:</strong> { invoiceData.created_at ? moment(invoiceData.created_at).format('DD/MM/YYYY') : null }<br />
                <strong>BOOKING TYPE: { invoiceData.booking.booking_type.name.toUpperCase() }</strong>
                <p>{ bookingDate.length > 0 ? bookingDate.map((date) => <p className="da-p-0 da-m-0">{ moment(date).format('DD/MM/YYYY') }</p>) : <>
                  { invoiceData.booking.start_date ? moment(invoiceData.booking.start_date).format('DD/MM/YYYY') : null } - { invoiceData.booking.end_date ? moment(invoiceData.booking.end_date).format('DD/MM/YYYY') : null } </> }</p>

              </Col>
            </Row>
            <Divider />
            <Row gutter={ 16 } style={ { maxHeight: 250, overflow: 'hidden' } }>
              <Col xs={ 24 } span={ 24 } className="da-pr-64 npm">
                <TableComponent scrollDisabled={ true } loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ false } />
              </Col>
            </Row>
            { invoiceAddons && invoiceAddons.length > 0 ? <>
              <Divider />
              <Row gutter={ 16 } style={ { maxHeight: 250, overflow: 'hidden' } }>
                <Col xs={ 24 } span={ 24 } className="da-pr-64 npm">
                  <h4 className="da-text-center da-mb-16">Addition Charges</h4>
                  <TableComponent scrollDisabled={ true } loader={ loader } columns={ columnsAddons } dataSource={ invoiceAddons } pagination={ false } />
                </Col>
              </Row>
            </>
              : null }
            <Divider />
            { allowAddional ? <>
              <Row className="hide-in-print" gutter={ 16 }>
                <Col span={ 24 } className="da-text-right">
                  <Button style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ onCreate } type="primary" className="da-mt-16">Add Additional</Button>
                </Col>
              </Row>
              <Divider />
            </>
              : null }
            <Row gutter={ 16 }>
              <Col xs={ 0 } span={ 12 } >
              </Col>
              <Col xs={ 24 } span={ 12 } className="da-pr-64 da-text-right npm">
                <h4 className="da-mb-24 da-mt-16">Total : £ { Number(invoiceData.total_price).toFixed(2) }</h4>
                <h4 className="da-mb-24">Booking Fee	: £ { invoiceData.booking.booking_fee ? Number(invoiceData.booking.booking_fee).toFixed(2) : 0 }</h4>
                { invoiceAddons.length > 0 ? <h4 className="da-mb-24"> Addition Charges	:  £ { Number(invoiceAddons.reduce((acc, curr) => acc + Number(curr.amount), 0)).toFixed(2) }</h4> : null }
                {/* <h4 className="da-mb-24">Unpaid Deposit	:  £ { invoiceData.booking.unpaid_deposit ? invoiceData.booking.unpaid_deposit : 0 }</h4> */ }
                <h4 className="da-mb-24">Paid Deposit	:  £ { Number(Number(invoiceData.booking.paid_deposit > 0 ? Number(invoiceData.is_paid && invoiceData.booking.booking_fee !== Number(Number(invoiceData.booking.paid_deposit > 0 ? Number(invoiceData.booking.booking_fee) + Number(invoiceData.booking.paid_deposit) - Number(invoiceData.total_price) : 0) + invoiceAddons.reduce((acc, curr) => curr.is_paid ? Number(acc) + Number(curr.amount) : acc, 0)) ? 0 : invoiceData.booking.booking_fee) + Number(invoiceData.booking.paid_deposit) : 0) + invoiceAddons.reduce((acc, curr) => curr.is_paid ? Number(acc) + Number(curr.amount) : acc, 0)).toFixed(2) }</h4>

                <h4 className="da-mb-64">Balance : { invoiceData.is_paid ? 0 : <>£ { invoiceData.booking.deposit_paid ? Number((invoiceData.total_price - invoiceData.booking.paid_deposit) + invoiceAddons.reduce((acc, curr) => curr.is_paid === 0 ? acc + Number(curr.amount) : acc, 0)).toFixed(2) : Number(Number(invoiceData.total_price) + Number(invoiceData.booking.booking_fee) + invoiceAddons.reduce((acc, curr) => curr.is_paid === 0 ? acc + Number(curr.amount) : acc, 0)).toFixed(2) } </> }</h4>

                <ButtonComponent className="hide-in-print" onClick={ handlePrint }><PrinterOutlined /></ButtonComponent>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
}

export default InvoiceIndex;