import React, { useState, useEffect } from "react";
import { ButtonComponent, } from "@comps/components";
import { Row, Col, Checkbox } from "antd";
import { makeRequest, notify } from '@utils/helpers';
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import { useSelector } from "react-redux";
import { getStyleRequest, updateStyleRequest } from "./requests";

const CrmStyling = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [bgDisplayColorPicker, setBgDisplayColorPicker] = useState(false);
  const [borderDisplayColorPicker, setBorderDisplayColorPicker] = useState(false);
  const [textDisplayColorPicker, setTextDisplayColorPicker] = useState(false);
  const [errors, setErrors] = useState([]);
  const [bgColor, setBgColor] = useState("red");
  const [borderColor, setBorderColor] = useState("green");
  const [textColor, setTextColor] = useState("blue");

  useEffect(() => {
    getStyle();
    // eslint-disable-next-line
  }, []);

  const getStyle = () => {
    makeRequest(setLoader, getStyleRequest, null, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    setBgColor(data.bg_color);
    setBorderColor(data.border_color);
    setTextColor(data.text_color);
  }

  const onError = (err) => {
    let errorList = [];
  }

  const onUpdateStyle = () => {
    let payload = {
      "bg_color": bgColor,
      "border_color": borderColor,
      "text_color": textColor
    }
    makeRequest(setLoader, updateStyleRequest, payload, onUpdateSuccess, onError);
  }

  const onUpdateSuccess = (data, res) => {
    localStorage.setItem("crmStyle", JSON.stringify(data));
    notify("Style", "Style updated successfully");
    window.location.reload();
  }



  const styles = reactCSS({
    'default': {
      bgColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: bgColor,
      },
      borderColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: borderColor,
      },
      textColor: {
        width: '40px',
        height: '20px',
        borderRadius: '2px',
        background: textColor,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'fixed',
        zIndex: '999',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });



  return (
    <>
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>CRM Styling</h4></Col>
              <Col span={ 12 } className="da-text-right"></Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mt-32">
            <Row gutter={ 16 } style={ { background: '#F8F9FA', padding: '10px' } }>
              <Col span={ 12 }><h5>Background Color</h5></Col>
              <Col span={ 12 } >
                <div style={ styles.swatch } onClick={ () => setBgDisplayColorPicker(!bgDisplayColorPicker) }>
                  <div style={ styles.bgColor } />
                </div>
                { bgDisplayColorPicker ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={ () => setBgDisplayColorPicker(false) } />
                  <SketchPicker color={ bgColor } onChange={ (color) => {
                    setBgColor(color.hex)
                  } } />
                </div> : null }
              </Col>
            </Row>
          </Col>
          <Col span={ 24 } className="da-mt-32">
            <Row gutter={ 16 } style={ { background: '#F8F9FA', padding: '10px' } }>
              <Col span={ 12 }><h5>Border Color</h5></Col>
              <Col span={ 12 } >
                <div style={ styles.swatch } onClick={ () => setBorderDisplayColorPicker(!borderDisplayColorPicker) }>
                  <div style={ styles.borderColor } />
                </div>
                { borderDisplayColorPicker ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={ () => setBorderDisplayColorPicker(false) } />
                  <SketchPicker color={ borderColor } onChange={ (color) => {
                    setBorderColor(color.hex)
                  } } />
                </div> : null }
              </Col>
            </Row>
          </Col>
          <Col span={ 24 } className="da-mt-32">
            <Row gutter={ 16 } style={ { background: '#F8F9FA', padding: '10px' } }>
              <Col span={ 12 }><h5>Text Color</h5></Col>
              <Col span={ 12 } >
                <div style={ styles.swatch } onClick={ () => setTextDisplayColorPicker(!textDisplayColorPicker) }>
                  <div style={ styles.textColor } />
                </div>
                { textDisplayColorPicker ? <div style={ styles.popover }>
                  <div style={ styles.cover } onClick={ () => setTextDisplayColorPicker(false) } />
                  <SketchPicker color={ textColor } onChange={ (color) => {
                    setTextColor(color.hex)
                  } } />
                </div> : null }
              </Col>
            </Row>
          </Col>
          <Col span={ 24 } className="da-mt-32">
            <ButtonComponent style={ { width: '150px', background: crmStyle.bg_color, border: crmStyle.border_color } } type="primary" onClick={ onUpdateStyle } loading={ loader } >Update</ButtonComponent>
          </Col>
        </Row>


      </div>
    </>
  );
}

export default CrmStyling;