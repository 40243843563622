import { get, post, del, put } from "@utils/axios";

const api = "configs-management";

export const getStyleRequest = () => {
  return get(`${api}/get-style`);
}

export const updateStyleRequest = (payload) => {
  return post(`${api}/update-style`, payload);
}

