import React, { useEffect, useState, } from "react";
import { Form, Input, Row, Upload, Col } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updatePetDocument, getPetDocument, deleteImage } from "../requests";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined } from '@ant-design/icons';

const formName = "editPetDocument";

const EditPetDocument = (props) => {
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({});

  // Image upload Start
  const [fileList, setFileList] = useState([]);


  const handlePreview = async (file) => {
    if (fileList.length > 0) {
      if (fileList[0].url) {
        window.open(fileList[0].url, "_blank")
      } else {
        window.open(process.env.REACT_APP_API_BASE_URL.slice(0, -4) + fileList[0].response.url, "_blank")
      }
    }
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={ {
          marginTop: 8,
        } }
      >
        Upload
      </div>
    </div>
  );
  // Image upload End

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getPetDocument, props.id, onDataSuccess, onError);
  }

  const onDataSuccess = (data, res) => {
    setData(data);
    if (data.object.image) {
      let imageObj = data.object.image;
      setFileList([{ "id": imageObj.id, "url": process.env.REACT_APP_API_BASE_URL.slice(0, -4) + imageObj.url, "name": imageObj.name, "status": "done", "uid": imageObj.id, path: imageObj.url }]);
    }
  }

  const onRemoveImage = (file) => {
    makeRequest(setLoader, deleteImage, file.response ? file.response.id : file.id, onSuccess, onError);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.id = props.id;
    if (fileList.length > 0 && fileList[0].response) {
      payload.object.image = fileList[0].response.url;
    } else if (fileList.length > 0 && fileList[0].url) {
      payload.object.image = fileList[0].path
    } else {
      payload.object.image = null
    }
    makeRequest(setLoader, updatePetDocument, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Pet Document", res.msg);
    props.onUpdated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  if (!data.object) return (<></>);
  return (

    <ModalComponent mainTitle="Update" subTitle="Pet Document" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ data.object }
      >
        <Row gutter={ 16 }>
          <Col span={ 18 }>
            <Form.Item name="title" rules={ rules.title } label="Title" className="da-mb-16"
              { ...getErrorProps(errors['title']) }>
              <Input placeholder="Document Title" />
            </Form.Item>
          </Col>
          <Col span={ 6 }>
            <Upload
              action={ process.env.REACT_APP_API_BASE_URL + "/upload-image" }
              listType="picture-card"
              fileList={ fileList }
              onPreview={ handlePreview }
              onChange={ handleChange }
              onRemove={ onRemoveImage }
            >{ fileList.length === 1 ? null : uploadButton }
            </Upload>
          </Col>
        </Row>
        <Form.Item name="description" label="Description" className="da-mb-16">
          <TextArea placeholder="Description" />
        </Form.Item>
      </Form>
    </ModalComponent>
  );


}

export default EditPetDocument

const rules = {
  title: [
    { required: true, message: 'Please input title!', },
  ],
  time: [
    { required: true, message: 'Please select time!', },
  ],

};
