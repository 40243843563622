import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Col, Form, Input, Row } from "antd";
import axios from "axios";
import noimage from '@assets/img/noimage.jpg';

import { getErrorProps } from "@utils/helpers";
import { ButtonComponent } from "@comps/components";
import { notify } from './../../../../utils/helpers';
const formName = "registerForm";


const Register = () => {
  const [webInData, setWebInData] = React.useState(null);
  const [errors, setErrors] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const history = useHistory();


  React.useEffect(() => {
    let webData = localStorage.getItem('webData');
    setWebInData(JSON.parse(webData));
    if (!webData) {
      axios.get(process.env.REACT_APP_API_BASE_URL + '/get-initial-data').then((res) => {
        localStorage.setItem('webData', JSON.stringify(res.data.data));
        setWebInData(res.data.data);
      })
    }

  }, []);

  const onFinish = (data) => {
    setLoader(true);
    let payload = { "object": data }
    payload.object.is_customer = true;
    payload.object.role_id = 2;
    axios.post(process.env.REACT_APP_API_BASE_URL + '/register', payload).then((res) => {
      if (res.data.code === 200) {
        notify('Registered Successfully', "you can login now");
        history.push('/');
        setLoader(false);
      } else {
        setLoader(false);
        let error = [];
        error["email"] = res.data.data;
        setErrors(error);
      }
    })
  }

  const RegisterForm = (props) => (
    <>
      <h3 className="da-mb-sm-0">Sign Up</h3>
      <p className="da-mt-sm-0 da-mt-8 da-text-color-black-60">
        Please fill below detail to create account.
      </p>
      <Form
        layout="vertical"
        name={ formName }
        initialValues={ { remember: true } }
        className="da-mt-sm-16 da-mt-32"
        onFinish={ onFinish }
      >
        <Form.Item name="name" rules={ rules.name } label="Full Name" className="da-mb-16"
          { ...getErrorProps(errors['name']) }>
          <Input placeholder="Enter Full Name" />
        </Form.Item>

        <Form.Item name="email" rules={ rules.email } label="Email" className="da-mb-16"
          { ...getErrorProps(errors['email']) }>
          <Input placeholder="Enter Email" />
        </Form.Item>

        <Form.Item name="password" rules={ rules.password } label="Password" className="da-mb-8"
          { ...getErrorProps(errors['password']) }>
          <Input.Password />
        </Form.Item>

        <Form.Item name="password_confirmation" dependencies={ ['password'] } rules={ [{ required: true, message: "Please input your password confirmation!" }, ({ getFieldValue }) => ({ validator(rule, value) { if (!value || getFieldValue('password') === value) { return Promise.resolve(); } return Promise.reject('Password confirmation does not match!'); }, })] } label="Confirm Password" className="da-mb-8"
          { ...getErrorProps(errors['password_confirmation']) }>
          <Input.Password />
        </Form.Item>


        <Form.Item className="da-mt-16 da-mb-8">
          <ButtonComponent state={ loader } style={ { background: props.crmStyle.bg_color, borderColor: props.crmStyle.border_color } } block type="primary" htmlType="submit">
            Register
          </ButtonComponent>
        </Form.Item>
      </Form>
      <div className="da-mt-64 da-text-center">
        <Link
          className="da-button da-text-color-success-80"
          to="/"
        >
          Go Back? Login
        </Link>
      </div>
    </>
  );

  if (webInData === null) return (<div>Loading...</div>);

  return (
    <div className="loginpage" style={ {
      backgroundImage: `url('${webInData.login_background ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.login_background : "/img/loginbg.jpg"}')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      minHeight: '1000px',
      overflow: 'hidden',
    } }>
      <Row gutter={ [32, 0] } >
        <Col lg={ 12 } span={ 24 } >
          <Col xxl={ 24 } xl={ 24 } lg={ 24 } md={ 24 } sm={ 24 } className="da-text-center da-px-sm-8 da-pt-24 da-pr-96">
            <img src={ webInData.letter_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webInData.letter_logo : noimage } width={ 150 } alt="img" />
          </Col>
          <Row align="middle" justify="center">
            <Col
              xxl={ 24 }
              xl={ 24 }
              lg={ 24 }
              md={ 24 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pl-96"
            >
              <h2 style={ { paddingTop: 100 } } class="login-header-text">{ webInData.login_text }</h2>
            </Col>
          </Row>
        </Col>
        <Col lg={ 12 } span={ 24 } >
          <Row align="middle" justify="center">
            <Col
              xxl={ 11 }
              xl={ 15 }
              lg={ 20 }
              md={ 20 }
              sm={ 24 }
              className="da-px-sm-8 da-pt-24 da-pb-48"
            >
              <div className="loginbox">
                { <RegisterForm crmStyle={ webInData.style } /> }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )

}

export default Register;

const rules = {
  name: [
    { required: true, message: "Please input your name!" },
  ],
  email: [
    { type: "email", message: "The input is not valid email!" },
    { required: true, message: "Please input your email!" },
  ],
  password: [
    { required: true, message: "Please input your password!" },
    { min: 6, message: 'Minimum password length is 6', },
    { max: 30, message: 'Maximum password length is 30', },
  ],
  password_confirmation: [
    { required: true, message: "Please input your password confirmation!" },
    { validator: (rule, value, callback) => { if (value && value !== formName.getFieldValue('password')) { callback('Password confirmation does not match!'); } else { callback(); } } },
  ],
};
