import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Button } from "antd";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getSettings } from './requests';
import { EditOutlined } from '@ant-design/icons';
import EditCrmSettings from './components/EditStripe';
import noimage from '@assets/img/noimage.jpg';

const StripeIndex = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);


  useEffect(() => {
    getSettingsData();
  }, []);

  const getSettingsData = () => {
    makeRequest(setLoader, getSettings, null, onDataSuccess, onDataError);
  }

  const onDataSuccess = (res) => {
    setData(res);
  }

  const onDataError = (error) => {
    notify(error);
  }

  const onEdit = () => {
    setChildComponent(<EditCrmSettings data={ data } onUpdated={ onUpdated } />);
  }

  const onUpdated = (status) => {
    if (status) {
      getSettingsData();
      setChildComponent(null);
    }
    setChildComponent(null);
  }

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      { childComponent }
      <div className="rightBox">
        <Row gutter={ 16 }>
          <Col span={ 24 } className="da-mb-12">
            <Row gutter={ 16 }>
              <Col span={ 12 }><h4>Stripe Settings</h4></Col>
              <Col className="da-text-right" span={ 12 }><Tooltip title="Update Setting" ><Button className="da-px-10 da-my-0" type="link" size="middle" onClick={ onEdit }><EditOutlined className="icon-style da-text-color-warning-1" /></Button></Tooltip></Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>Stripe Public Key</Col>
              <Col className="da-text-right" span={ 16 }>
                <p style={ {
                  wordBreak: 'break-all',
                  paddingRight: '10px'
                } }>{ data && data.stripe_public_key }</p>
              </Col>
            </Row>
            <Row className="da-mb-32" gutter={ 16 }>
              <Col span={ 8 }>Stripe Secret Key</Col>
              <Col className="da-text-right" span={ 16 }>
                <p style={ {
                  wordBreak: 'break-all',
                  paddingRight: '10px'
                } }>{ data && data.stripe_secret_key }</p>
              </Col>
            </Row>
          </Col>
        </Row >

      </div >
    </>
  );
}

export default StripeIndex;