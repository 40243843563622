import React, { useState, useEffect } from "react";
import { TableComponent, HeaderComponent, BodyComponent, ButtonComponent } from "@comps/components";
import { useParams } from "react-router-dom";
import { makeRequest, notify, makeRequestStateless } from '@utils/helpers';
import { getBookingPetRequest, getBookingPetCheckinRequest, getBookingPetCheckoutRequest, getAllClientsRequest } from './requests';
import { Row, Col, Select, Input } from "antd";
import DatePicker from "react-multi-date-picker"
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Pet Attendance",
    breadcrumb: [
      {
        name: "Pet Attendance",
        path: "/pet-management/attendance"
      }
    ]
  }
}

const IndexBookingPet = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [clients, setClients] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      render: (record) => moment(record).format('DD/MM/YYYY')
    },
    {
      key: 'pet_name',
      title: 'Pet Name',
      dataIndex: 'pet_name',
      sorter: true,
    },
    {
      key: 'pet_type_name',
      title: 'Pet Type',
      dataIndex: 'pet_type_name',
      sorter: true,
    },
    {
      key: 'owner_name',
      title: 'Owner',
      dataIndex: 'owner_name',
      sorter: true,
    },
    {
      key: 'check_in',
      title: 'Check In',
      sorter: true,
      render: (record) => {
        return record.checked_in ? <>{ moment(record.checked_in).format('DD/MM/YYYY') }</> : <ButtonComponent style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ () => onCheckIn(record) }>Check In</ButtonComponent>;
      }
    },
    {
      key: 'check_out',
      title: 'Check Out',
      sorter: true,
      render: (record) => {
        return !record.checked_out && <>{ moment(record.checked_out).format('DD/MM/YYYY') }</> ? <><ButtonComponent style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ () => onCheckOut(record) }>Check Out</ButtonComponent></> : <>{ record.checked_out ? record.checked_out : null }</>;
      }
    },
  ];

  useEffect(() => {
    getData();
    getAllClients();
  }, []);

  const getData = () => {
    makeRequest(setLoader, getBookingPetRequest, id, onDataSuccess, onError);
  }

  const onDataSuccess = (res, response) => {
    setDataSource(response.data);
    setOrgData(response.data);
    setTotalRecords(response.total);
  }

  const onCheckIn = (payload) => {
    makeRequest(setLoader, getBookingPetCheckinRequest, payload.id, onCheckInSuccess, onError);
  }

  const onCheckInSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onCheckOut = (payload) => {
    makeRequest(setLoader, getBookingPetCheckoutRequest, payload.id, onCheckOutSuccess, onError);
  }

  const onCheckOutSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onError = (error, msg) => {
    //
  }


  const getAllClients = () => {
    makeRequestStateless(getAllClientsRequest, null, onGetAllClientsSuccess, Function);
  }


  const onGetAllClientsSuccess = (response) => {
    setClients(response);
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  //Filters
  const onPetNameChange = (value) => {
    let data = orgData;
    if (value) {
      data = data.filter((d) => d.pet_name.toLowerCase().includes(value.toLowerCase()));
    }
    setDataSource(data);
  }

  const debouncedGetPetByName = debounce((e) => {
    onPetNameChange(e.target.value);
  }, 300);

  const onClientChange = (value) => {
    let clientName = clients.find((d) => d.value === value)?.label.split(' - ')[0];
    let data = orgData;
    if (value) {
      data = data.filter((d) => d.owner_name.toLowerCase().includes(clientName.toLowerCase()));
    }
    setDataSource(data);
  }

  const onCheckinDateChange = (value) => {

    let selectDate = moment.unix(value.unix).format('YYYY-MM-DD');
    const filterData = orgData.filter((item) => {
      const checkInDate = moment(item.checked_in).format('YYYY-MM-DD');
      return checkInDate === selectDate;
    });
    setDataSource(filterData);

  }


  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32 da-mt-32">
          <Col xs={ 24 } lg={ 6 }>
            <Input placeholder="Search By Pet Name" onChange={ debouncedGetPetByName } />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <Select
              style={ { width: '100%' } }
              showSearch
              onChange={ onClientChange }
              filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
              placeholder="Search By Clients"
              options={ clients }
            />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <DatePicker
              onChange={ onCheckinDateChange }
              format={ "DD/MM/YYYY" }
              placeholder="Select Date"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
            />
          </Col>
          {/* <Col xs={ 24 } lg={ 6 }>
            <DatePicker
              onChange={ onCheckOutDateChange }
              format={ "YYYY-MM-DD" }
              placeholder="Select checkout date"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
            />
          </Col> */}
        </Row>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexBookingPet;