import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "./dashboard/components/Dashboard";
import PetDetailIndex from "./dashboard/components/PetDetailIndex";
import UserModule from "./userManagement/routes";
import SettingModule from "./settings/routes";
import ClientModule from "./clientManagement/routes";
import PetModule from "./petManagement/routes";
import BookingModule from "./bookingManagement/routes";
import IndexAvailabilityChecker from "./availabilitychecker/IndexAvailabilityChecker";
import InvoiceIndex from "./bookingManagement/invoice/InvoiceIndex";
import BookingCarePlan from "./bookingManagement/bookingcareplan/BookingCarePlan";
import TransactionIndex from "./transactionManagement/TransactionIndex";
import ReportModule from "./reportsManagement/routes";
import StripePay from "./paymentManagement/StripePay";
import PaymentSuccess from "./paymentManagement/status/PaymentSuccess";
import PaymentError from "./paymentManagement/status/PaymentError";

import PermissionDenied from "./dashboard/PermissionDenied";

//Client Routes
import ClientDashboard from "./dashboard/ClientDashboard";

const loginUser = JSON.parse(localStorage.getItem("user"));

const ModuleRoutes = ({ match }) => (

  <Switch>
    <Route path={ `${match.url}user-management` } component={ loginUser ? loginUser.role_id === 1 || loginUser.role_id === 3 ? UserModule : PermissionDenied : PermissionDenied } />
    <Route path={ `${match.url}settings` } component={ loginUser ? loginUser.role_id === 1 || loginUser.role_id === 3 ? SettingModule : PermissionDenied : PermissionDenied } />
    <Route path={ `${match.url}client-management` } component={ ClientModule } />
    <Route path={ `${match.url}pet-management` } component={ PetModule } />
    <Route path={ `${match.url}booking-management` } component={ BookingModule } />
    <Route path={ `${match.url}availability` } component={ IndexAvailabilityChecker } />
    <Route path={ `${match.url}invoice/:id` } component={ InvoiceIndex } />
    <Route path={ `${match.url}bookingcareplan/:id` } component={ BookingCarePlan } />
    <Route path={ `${match.url}transactions` } component={ TransactionIndex } />
    <Route path={ `${match.url}report-management` } component={ ReportModule } />
    <Route path={ `${match.url}payment-success` } component={ PaymentSuccess } />
    <Route path={ `${match.url}payment-error` } component={ PaymentError } />
    <Route path={ `${match.url}payment-stripe/:invoiceno` } component={ StripePay } />
    <Route path={ `${match.url}pet-details/:date/:petType` } component={ PetDetailIndex } />
    <Route path={ `${match.url}` } component={ loginUser ? loginUser.role_id === 2 ? ClientDashboard : Dashboard : ClientDashboard } />
  </Switch>
);

export default ModuleRoutes;
