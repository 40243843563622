import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { TabComponent, HeaderComponent, ButtonComponent } from "@comps/components";
import { makeRequest, notify } from "@utils/helpers";
import { getPet } from "../requests";
import AboutPet from './view/AboutPet';
import { Row, Col } from 'antd';
import IndexPetDiet from '../../petdiets/PetDietIndex';
import IndexPetMedical from '../../petmedicals/IndexPetMedical';
import IndexPetDocument from '../../petdocuments/IndexPetDocument';
import noimage from '@assets/img/noimage.jpg';
import PetBookings from './view/PetBookings';
import { useSelector } from 'react-redux';
const moment = require('moment');


const pageConfig = {
  headers: {
    title: "Pet details",
    breadcrumb: [
      {
        name: "Pets",
        path: "/pet-management/pets"
      },
      {
        name: "View Pet",
        path: "/pet-management/pets/view"
      }
    ]
  }
}

const ViewPet = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.search.split("=")[1]);
  const history = useHistory();
  const [data, setData] = useState({
    object: null,
    dependencies: []
  });

  const onCharePlanBtn = () => {
    history.push(`/pet-management/pets/careplan/${id}`);
  }



  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getPet, id, onSuccess, onError);
  }

  const onSuccess = (res) => {
    setData(res);
  }

  const goBack = () => {
    history.push(`/pet-management/pets`);
  }


  const tabs = [
    {
      title: "About Pet",
      content: <AboutPet id={ id } data={ data.object } dependencies={ data.dependencies } />
    },
    {
      title: "Diet",
      content: <IndexPetDiet id={ id } />
    },
    {
      title: "Medical",
      content: <IndexPetMedical id={ id } />
    },
    {
      title: "Documents",
      content: <IndexPetDocument id={ id } />
    },
    {
      title: "Bookings",
      content: <PetBookings crmStyle={ crmStyle } id={ id } />
    },
  ]

  const onError = (res) => {
    if (res.code === 403) {
      notify("Error", res.msg, "error")
    }
  }

  const handleTabChange = (key) => {
    setActiveTab(key);
  }
  if (data.object == null) return (<div></div>);

  return (
    <>
      <style>{ `.ant-btn:hover {border-color:${crmStyle.bg_color} !important;color:${crmStyle.bg_color} !important;}

`}</style>
      <Row gutter={ 16 }>
        <Col span={ 24 }>
          <HeaderComponent headers={ pageConfig.headers }>
            <ButtonComponent onClick={ goBack }>Go Back</ButtonComponent>
          </HeaderComponent>
        </Col>
        <Col xs={ 24 } className="da-p-12 da-pl-24 " lg={ 7 }>
          <div className="da-text-center borderbottom da-pb-24">
            <img className="avatar" src={ data.object.image ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + data.object.image.url : noimage } alt="client" />
            <h5 className="da-mt-12">{ data.object.name }</h5>
            <Row>
              <Col className="da-mt-24" span={ 12 }>
                <strong>About</strong>
              </Col>
              <Col className="da-mt-24" span={ 12 }>
                Pet
              </Col>
              <Col className="da-mt-24" span={ 12 }>
                <strong>Client</strong>
              </Col>
              <Col className="da-mt-24" span={ 12 }>
                { data.object.owner_name }
              </Col>
              <Col className="da-mt-24" span={ 12 }>
                <strong>Vacc. Date</strong>
              </Col>
              <Col className="da-mt-24" span={ 12 }>
                { data.object.vaccination_date ? moment(data.object.vaccination_date).format('DD/MM/YYYY') : null }
              </Col>
            </Row>
          </div>
          <div className="da-text-center">
            <ButtonComponent type="primary" style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } className="da-mt-24" onClick={ onCharePlanBtn }>Care Plan</ButtonComponent>
          </div>
        </Col>
        <Col xs={ 24 } lg={ 17 }>
          <TabComponent onChange={ handleTabChange } activeKey={ activeTab } headers={ false } tabs={ tabs } loader={ loader }></TabComponent>
        </Col>
      </Row>
    </>
  );
}

export default ViewPet;
