import React, { useEffect, useState } from "react";
import { Form, DatePicker, Select, Input } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { updateLimitByRang, getDependencies, getLimitByRang } from "../requests";
const moment = require('moment');

const formName = "updateLimitByRang";

const EditLimitByRang = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [bookingTypes, setBookingTypes] = useState([]);
  const [data, setData] = useState({
    object: null,
  });


  useEffect(() => {
    getDepdencyData();
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getLimitByRang, props.id, onDataSuccess, onError);

  }

  const onDataSuccess = (res) => {
    res.object.start_date = moment(new Date(res.object.start_date));
    res.object.end_date = moment(new Date(res.object.end_date));
    setData(res);
  }

  const getDepdencyData = () => {
    makeRequest(setLoader, getDependencies, null, onDepDataSuccess, onError);
  }

  const onDepDataSuccess = (res) => {
    setBookingTypes(res.booking_types);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.id = props.id;
    payload.object.start_date = payload.object.start_date.format('YYYY-MM-DD');
    payload.object.end_date = payload.object.end_date.format('YYYY-MM-DD');
    makeRequest(setLoader, updateLimitByRang, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("LimitByRang", res.msg);
    props.onUpdated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  if (!data.object) return (<></>);

  return (

    <ModalComponent mainTitle="Update" subTitle="Booking Limit By Range" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ data.object }
      >
        <Form.Item name="booking_type_id" rules={ rules.booking_type_id } label="Booking Types" className="da-mb-16"
          { ...getErrorProps(errors['booking_type_id']) }
        >
          <Select options={bookingTypes} placeholder="Select Booking Types" className="da-w-100" />
        </Form.Item>

        <Form.Item name="start_date" rules={ rules.start_date } label="Start Date" className="da-mb-16"
          { ...getErrorProps(errors['start_date']) }
        >
          <DatePicker className="da-w-100" />
        </Form.Item>

        <Form.Item name="end_date" rules={ rules.end_date } label="End Date" className="da-mb-16"
          { ...getErrorProps(errors['end_date']) }
        >
          <DatePicker className="da-w-100" />
        </Form.Item>

        <Form.Item name="limit" rules={ rules.limit } label="Days Limit" className="da-mb-16"
          { ...getErrorProps(errors['limit']) }
        >
          <Input type="number" placeholder="Enter limit days number" className="da-w-100" />
        </Form.Item>

      </Form>
    </ModalComponent>
  );


}

export default EditLimitByRang

const rules = {
  booking_type_id: [
    { required: true, message: 'Please select booking type', },
  ],
  start_date: [
    { required: true, message: 'Please select start date', },
  ],
  end_date: [
    { required: true, message: 'Please select end date', },
  ],
  limit: [
    { required: true, message: 'Please enter limit days number', },
  ],
};
