import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, CreateButton } from "@comps/components";
import { Row, Col, Tooltip, Button } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { makeRequest, removeById, notify, replaceById } from '@utils/helpers';
import { Icon } from '@iconify/react';
import { getPets, deletePet } from './requests';
import noimage from '@assets/img/noimage.jpg';


const ClientPets = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'name',
      title: 'Pet Name',
      sorter: false,
      render: (record) => <><img style={ { width: '30px', height: '30px', borderRadius: '100%', marginRight: '20px' } } src={ record.image ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + record.image : noimage } alt="pet" /> { record.name }</>
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <div className="da-text-right">
        <Tooltip title="Pet Profile" ><Button onClick={ () => history.push(`/pet-management/pets/view/${record.id}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><Icon icon="material-symbols:pets" /></Button></Tooltip>
        <ActionComponent each={ record } onEdit={ onEdit } onDelete={ onDelete } />
      </div>
    },
  ];

  useEffect(() => {
    makeRequest(setLoader, getPets, id, onSuccess, onError);
  }, [pagination]);

  const onSuccess = (data, response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  const onDelete = (record) => {
    makeRequest(setLoader, deletePet, record.id, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    setDataSource(removeById(dataSource, response.id));
    notify("Pet", msg.msg)
  }

  const onError = (error, msg) => {
    //
  }

  const onEdit = (record) => {
    history.push(`/pet-management/pets/edit/${record.id}`);
  }

  const onCreate = () => {
    history.push(`/pet-management/pets/create`);
  }




  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  return (
    <>
      { childComponent }
      <Row gutter={ 16 }>
        <Col span={ 24 } className="da-text-right da-mb-12">
          <CreateButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } onClick={ onCreate } text="Add Pet " />
        </Col>
      </Row>
      <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
    </>
  );
}

export default ClientPets;