import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col, Upload } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { useSelector } from 'react-redux';
import { deleteImage, updateSettings } from "../requests";


const formName = "updateStripeForm";

const EditStripe = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);



  const onFinish = (values) => {
    let payload = {
      "object": values,
    }
    makeRequest(setLoader, updateSettings, payload, onUpdatSuccess, onUpdatError);
  }

  const onUpdatSuccess = (data, res) => {
    notify("Site Setting", res.msg);
    props.onUpdated(true);
  }

  const onUpdatError = (data) => {
    setErrors(data);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } form={ formName } key="update_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onUpdated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent width={ 1000 } mainTitle="Edit" subTitle="Stripe Setting" visible={ true } footer={ footer } onCancel={ () => props.onUpdated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
        initialValues={ props.data }
      >
        <Row gutter={ 16 }>
          <Col span={ 24 }>
            <Form.Item name="stripe_public_key" rules={ rules.stripe_public_key } label="Stripe Public Key" className="da-mb-16"
              { ...getErrorProps(errors['stripe_public_key']) }
            >
              <Input placeholder="Enter Stripe Public Key" />
            </Form.Item>
          </Col>
          <Col span={ 24 }>
            <Form.Item name="stripe_secret_key" rules={ rules.stripe_secret_key } label="Stripe Secret Key" className="da-mb-16"
              { ...getErrorProps(errors['stripe_secret_key']) }
            >
              <Input placeholder="Enter Stripe Secret Key" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalComponent>
  );
}

export default EditStripe

const rules = {
  stripe_secret_key: [
    { required: true, message: 'Please input your stripe secret key!', },
  ],
  stripe_public_key: [
    { required: true, message: 'Please input your stripe public key!', },
  ],

};
