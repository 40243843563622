import { get, post, del, put } from "@utils/axios";

const api = "pets-management/pet-types";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getPetTypeRequest = (id) => {
  return get(api);
}

export const getPetType = (id) => {
  return get(`${api}/${id}`);
}

export const createPetType = (payload) => {
  return post(api, payload);
}

export const updatePetType = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deletePetType = (id) => {
  return del(`${api}/${id}`);
}

