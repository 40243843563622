import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexPet from "./pets/IndexPet";
import CreatePet from "./pets/components/CreatePet";
import EditPet from "./pets/components/EditPet";
import ViewPet from "./pets/components/ViewPet";
import IndexBookingPet from "./petAttendance/IndexPetAttendance";
import PetCarePlan from "./pets/components/PetCarePlan";
import CreateClientPet from "./pets/components/client/CreateClientPet";
import EditClientPet from "./pets/components/client/EditClientPet";
import PermissionDenied from './../dashboard/PermissionDenied';

const loginUser = JSON.parse(localStorage.getItem("user"));

const PetModule = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.url}/pets` } component={ IndexPet } />
    <Route exact path={ `${match.url}/pets/create` } component={ loginUser ? loginUser.role_id === 2 ? CreateClientPet : CreatePet : CreateClientPet } />
    <Route exact path={ `${match.url}/pets/edit/:id` } component={ loginUser ? loginUser.role_id === 2 ? EditClientPet : EditPet : EditClientPet } />
    <Route exact path={ `${match.url}/pets/view/:id` } component={ ViewPet } />
    <Route exact path={ `${match.url}/pets/careplan/:id` } component={ PetCarePlan } />
    <Route exact path={ `${match.url}/attendance` } component={ loginUser ? loginUser.role_id === 2 ? PermissionDenied : IndexBookingPet : PermissionDenied } />

  </Switch>
);

export default PetModule;
