import React, { useEffect, useState } from "react";
import { Form, Input, Row, Col } from "antd";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color';
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { createPetBreed } from "../requests";
import TextArea from "antd/lib/input/TextArea";


const formName = "createPetBreed";

const CreatePetBreed = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);


  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onFinish = (data) => {
    let payload = { "object": data }
    makeRequest(setLoader, createPetBreed, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("PetBreed", res.msg);
    props.onCreated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent mainTitle="Create" subTitle="Pet Breed" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Form.Item name="name" rules={ rules.name } label="Name" className="da-mb-16"
          { ...getErrorProps(errors['name']) }
        >
          <Input placeholder="Breed Name" />
        </Form.Item>

        <Form.Item name="description" rules={ rules.description } label="Description" className="da-mb-16"
          { ...getErrorProps(errors['description']) }
        >
          <TextArea placeholder="Enter description" />
        </Form.Item>


      </Form>
    </ModalComponent>
  );


}

export default CreatePetBreed

const rules = {
  name: [
    { required: true, message: 'Please input your name!', },
  ],
  description: [
  ],


};
