import { get, post, del, put } from "@utils/axios";

const api = "availability-management";


export const availabilityCheckRequest = (payload) => {
    return post(`${api}/availability-checker`, payload);
}

export const getCheckerDependencies = () => {
    return get(`${api}/dependencies`);
}
