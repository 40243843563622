import { get, post, del, put } from "@utils/axios";

const api = "report-management/"

export const getPetReport = () => {
  return get(`${api}get-pet-report`);
}


export const getBookingReport = () => {
  return get(`${api}get-booking-report`);
}

export const getSalesReport = (payload) => {
  return get(`${api}get-sales-report/${payload.start_date}/${payload.end_date}`);
}

export const getAllClientsRequest = () => {
  return get(`/booking-management/bookings-get-all-clients`);
}
