import { get, post, del, put } from "@utils/axios";

const api = "booking-management/booking-limit-range";

export const getFilters = () => {
  return get(`${api}/filters`);
}

export const getLimitByRangRequest = (id) => {
  return get(api);
}

export const getDependencies = () => {
  return get(`${api}/dependencies`);
}

export const getLimitByRang = (id) => {
  return get(`${api}/${id}`);
}

export const changeModuleStatus = (status) => {
  return get(`booking-management/booking-limit-range-status/${status}`);
}
export const getModuleStatusReq = () => {
  return get(`booking-management/booking-limit-range-status`);
}

export const createLimitByRang = (payload) => {
  return post(api, payload);
}

export const updateLimitByRang = (payload) => {
  return put(`${api}/${payload.id}`, payload);
}

export const deleteLimitByRang = (id) => {
  return del(`${api}/${id}`);
}

