import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Checkbox } from "antd";
import { useHistory } from "react-router-dom";

import { SaveButton, BodyComponent, HeaderComponent, TableComponent } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { createBooking, getBookingDependencies, getPetsByType, getPriceByPets, cheeckClientPaymentRequest, cheeckClientBookingLimitRequest, cheeckClientBookingLimitByRangRequest } from "../requests";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { useSelector } from 'react-redux';
import ClientStripePay from "../../../paymentManagement/ClientStripePay";

const userData = JSON.parse(localStorage.getItem("user"));

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Make Booking",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      },
      {
        name: "Make Booking",
        path: "/booking-management/bookings/create"
      }
    ]
  }
}
const formName = "createBooking";
const CreateClientBooking = (props) => {
  const [form] = Form.useForm();
  const [childComponent, setChildComponent] = useState(null);
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [webData, setWebData] = useState(localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {});
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [petData, setPetData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(userData.id);
  const [termOne, setTermOne] = useState(false);
  const [termTwo, setTermTwo] = useState(false);
  const [bookingPetData, setBookingPetData] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [totalBookingDays, setTotalBookingDays] = useState(0);
  const [totalSelectedPet, setTotalSelectedPet] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [limit, setLimit] = useState(null);
  const [minLimit, setMinLimit] = useState(0);
  const [disableBooking, setDisableBooking] = useState(false);
  const [transactionNo, setTransactionNo] = useState(Math.floor(Math.random() * 90000) + 10000);
  const [selectedPetType, setSelectedPetType] = useState([]);
  const [currentError, setCurrentError] = useState([]);
  const [selectedBookingType, setSelectedBookingType] = useState(null);
  const history = useHistory();
  const [deps, setDeps] = useState({
    bookingTypes: [],
    clients: [],
    petTypes: [],
    disableDays: [],
    disableDates: []
  });

  const getSelectFieldsData = () => {
    makeRequestStateless(getBookingDependencies, null, onDependencySuccess, null);
  }

  const columns = [
    {
      key: 'total_select_pets',
      title: 'Total Selected Pets',
      dataIndex: 'total_select_pets',
      sorter: false,
    },
    {
      key: 'selected_pet_type',
      title: 'Selected Pet Type',
      dataIndex: 'selected_pet_type',
      sorter: false,
    },
    {
      key: 'selected_booking_type',
      title: 'Selected Booking Type',
      dataIndex: 'selected_booking_type',
      sorter: false,
    },
    {
      key: 'total_days',
      title: 'Total Days',
      dataIndex: 'total_days',
      sorter: false,
    },
    {
      key: 'total_cost',
      title: 'Total Cost',
      dataIndex: 'total_cost',
      sorter: false,
    },
  ];

  useEffect(() => {
    onCheckBookingStatus();
    getSelectFieldsData();
    // eslint-disable-next-line
  }, []);

  const onCheckBookingStatus = () => {
    let payload = localStorage.getItem('bookingPayload') ? JSON.parse(localStorage.getItem('bookingPayload')) : null;
    let bookingPaymentId = localStorage.getItem('bookingPaymentId');
    if (bookingPaymentId && payload) {
      makeRequestStateless(cheeckClientPaymentRequest, payload.object.client_id, (data) => onPaymentSuccess(data, payload), onPaymentError);
    }
  }

  const onPetTypeChange = (value) => {
    let payload = { "client": selectedClient, "pet_type": value }
    setSelectedPetType(value);
    setLimit(deps.petTypes.find((obj) => obj.value === value).limit);
    makeRequestStateless(getPetsByType, payload, onPetSuccess, null);
  }

  const onChangeBookingType = (value) => {
    let obj = deps.bookingTypes.find((obj) => obj.value === value);
    setSelectedBookingType(obj);
  }

  const onPetChange = (value) => {
    setTotalSelectedPet(value.length);

  }

  const onSelectStartDate = (value) => {
    let date = value.unix;
    setStartDate(value);
    getDisabledDayDates(value);
    if (date) {
      let date = moment.unix(value.unix).format('YYYY-MM-DD');
      let payload = {
        "pet_type_id": selectedPetType,
        "booking_type_id": selectedBookingType.value,
        "pet_no": totalSelectedPet,
        "date": date
      }
      makeRequestStateless(getPriceByPets, payload, (res) => onPriceSuccess(res, totalSelectedPet, value), onPriceDataError);
    }
  }

  const onChangeDaycareDate = (value) => {

    getDisabledDayDates(value);

    if (value.length > 0) {
      let date = moment.unix(value[0].unix).format('YYYY-MM-DD');
      let payload = {
        "pet_type_id": selectedPetType,
        "booking_type_id": selectedBookingType.value,
        "pet_no": totalSelectedPet,
        "date": date
      }
      setTotalBookingDays(value.length)
      makeRequestStateless(getPriceByPets, payload, (res) => onPriceDaycareSuccess(res, totalSelectedPet, value), onPriceDataError);
    } else {
      setBookingPetData([])
    }
  }

  const onPriceDaycareSuccess = (res, petsno, totaldays) => {
    let obj = [{
      "total_select_pets": petsno,
      "selected_pet_type": res.pet_type_name,
      "selected_booking_type": res.booking_type.name,
      "total_days": totaldays.length,
      "total_cost": res.prices * totaldays.length
    }]

    setTotalPrice(res.prices);
    setBookingPetData(obj);
    let sortDates = totaldays.sort((a, b) => a.unix - b.unix);
    let payload = {
      "booking_type_id": selectedBookingType.value,
      "start_date": moment.unix(sortDates[0].unix).format('YYYY-MM-DD'),
    }

    makeRequestStateless(cheeckClientBookingLimitByRangRequest, payload, (res) => onLimitByRangeSuccess(res, totalSelectedPet), onLimitError);
  }

  const onPriceSuccess = (res, petsno, value) => {
    let obj = [{
      "total_select_pets": petsno,
      "selected_pet_type": res.pet_type_name,
      "selected_booking_type": res.booking_type.name,
      "total_days": totalBookingDays,
      "total_cost": res.prices * totalBookingDays
    }]
    let date = moment.unix(value.unix).format('YYYY-MM-DD');
    setTotalPrice(res.prices);
    setBookingPetData(obj);

    let payload = {
      "booking_type_id": selectedBookingType.value,
      "start_date": date,
    }

    makeRequestStateless(cheeckClientBookingLimitByRangRequest, payload, (res) => onLimitByRangeSuccess(res, totalSelectedPet), onLimitError);
  }

  const onLimitByRangeSuccess = (res, petsno) => {
    setMinLimit(res);
  }

  const onPriceDataError = (err, res) => {
    notify("Error", res.msg, 'error');
    setTotalSelectedPet(null);
  }

  const onChangeEndDate = (value) => {
    let bookingDates = [];

    if (value.unix) {
      let startdate = moment.unix(startDate.unix).format('YYYY-MM-DD');
      let enddate = moment.unix(value.unix).format('YYYY-MM-DD');
      let currentDate = moment(startdate);
      let endDate = moment(enddate);
      while (currentDate <= endDate) {
        let day = moment(currentDate).day();

        if (deps.disableDays.includes(day) || deps.disableDates.includes(currentDate.format('YYYY-MM-DD'))) {

        } else {
          bookingDates.push(currentDate.format('YYYY-MM-DD'));
        }
        currentDate = moment(currentDate).add(1, 'days');
      }

      bookingDates.forEach((e) => {
        getDisabledDayDates(new Date(e));
      })



      bookingPetData[0].total_days = bookingDates.length;
      bookingPetData[0].total_cost = bookingDates.length * totalPrice;
      setTotalBookingDays(bookingDates.length);
      setBookingPetData([...bookingPetData]);
    } else {
      bookingPetData[0].total_days = value.length;
      bookingPetData[0].total_cost = value.length * totalPrice;
      setTotalBookingDays(value.length);
      setBookingPetData([...bookingPetData]);
    }
    let payload = {
      "booking_type_id": selectedBookingType.value,
      "start_date": startDate,
    }

    makeRequestStateless(cheeckClientBookingLimitByRangRequest, payload, (res) => onLimitByRangeSuccess(res, totalSelectedPet), onLimitError);

    if (minLimit !== 0 && bookingDates.length < minLimit) {
      notify("Error", `You can not book less than ${minLimit} days`, 'error');
      return;
    }

  }

  const onPetSuccess = (data, res) => {
    setPetData(res.data);
  }


  const onDependencySuccess = (data, res) => {
    if (data.bookingTypes.length === 0) {
      notify("Booking", "Please create booking types first", 'error');
    }
    setDeps({
      bookingTypes: data.bookingTypes,
      clients: data.clients,
      petTypes: data.petTypes,
      disableDays: data.disabledDays,
      disableDates: data.disableDates
    });
  }
  const onFinish = (data) => {
    if (totalSelectedPet < 1) {
      notify("Error", "Please select pets", 'error');
      return;
    }

    if (!termOne || !termTwo) {
      setCurrentError({ onTermError: true });
      return;
    }
    let payload = { "object": data }
    if (data.booking_dates && data.booking_dates.length > 0) {
      let bookingDates = data.booking_dates.map((date) => {
        return moment.unix(date.unix).format('YYYY-MM-DD');
      })
      payload.object.booking_dates = bookingDates.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });
      payload.object.start_date = data.booking_dates[0];
      payload.object.user_id = JSON.parse(localStorage.getItem('user')).id
      payload.object.end_date = data.booking_dates[data.booking_dates.length - 1];
      payload.object.total_days = bookingDates.length;

      if (minLimit !== 0 && bookingDates.length < minLimit) {
        notify("Error", `You can not book less than ${minLimit} days`, 'error');
        return;
      }

    } else {
      if (data.start_date === undefined || data.end_date === undefined) {
        setCurrentError({ onDateError: true });
        return;
      }
      payload.object.start_date = moment.unix(data.start_date.unix).format('YYYY-MM-DD');
      payload.object.end_date = moment.unix(data.end_date.unix).format('YYYY-MM-DD');
      let bookingDates = [];
      let currentDate = moment(payload.object.start_date);
      let endDate = moment(payload.object.end_date);
      while (currentDate <= endDate) {
        bookingDates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }
      bookingDates.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });

      if (minLimit !== 0 && bookingDates.length < minLimit) {
        notify("Error", `You can not book less than ${minLimit} days`, 'error');
        return;
      }

      var bookingNotallow = false;

      bookingDates.forEach((e) => {

        if (getDisabledDayDates(new Date(e))) {
          bookingNotallow = true;
        }
      })

      if (bookingNotallow) {
        return;
      }

      payload.object.booking_dates = bookingDates;
      payload.object.total_days = bookingDates.length;
    }
    if (payload.object.total_days < 1) {
      notify("Error", "Please select valid dates end date can not be less than start date", 'error');
      return;
    }
    payload.object.deposit_paid = data.deposit;
    payload.object.agree_terms = termOne;
    payload.object.limit = limit;
    payload.object.range = selectedBookingType.range;
    payload.object.agree_terms_two = termTwo;
    payload.object.client_id = selectedClient;
    payload.object.pet_pet_price = totalPrice / totalSelectedPet;
    payload.object.totalPrice = totalPrice * payload.object.total_days;
    payload.object.totalPets = totalSelectedPet;
    payload.object.totalPets = totalSelectedPet;
    payload.object.depositPercent = selectedBookingType.deposit;
    payload.object.totalDeposit = Number(payload.object.totalPrice * selectedBookingType.deposit / 100).toFixed(2);
    payload.object.status = "clientBooking";
    payload.object.randomId = transactionNo;

    let bookingPayload = {
      "object": {
        "pet_type_id": selectedPetType,
        "dates": payload.object.booking_dates,
      }
    }
    makeRequestStateless(cheeckClientBookingLimitRequest, bookingPayload, (data) => onLimitSuccess(data, payload), onLimitError);
  }

  const onLimitSuccess = (res, payload) => {
    let allowbooking = true;
    payload.object.booking_dates.map((date) => {
      let obj_date = moment(date);
      let weekday = obj_date.day();
      if (weekday === 0 && !selectedBookingType.range) {
        allowbooking = false;
      }
    })
    if (!allowbooking) {
      notify("Error", "Sunday is not available for booking", 'error');
      return;
    }

    localStorage.setItem('bookingPayload', JSON.stringify(payload));
    localStorage.setItem('bookingPaymentId', transactionNo);
    setChildComponent(<ClientStripePay payload={ payload } data={ JSON.stringify(payload.object) } onCreated={ onCreated } />)
  }

  const onLimitError = (data, res) => {
    notify("Booking Error", res.msg, "error");
  }


  const onSuccess = (data, res) => {
    localStorage.removeItem('bookingPayload');
    localStorage.removeItem('bookingPaymentId');
    notify("Thanks for payment your booking is complete", "success");
    history.push('/booking-management/bookings');
  }

  const onCreated = (payload) => {
    makeRequestStateless(cheeckClientPaymentRequest, payload.object.client_id, (data) => onPaymentSuccess(data, payload), onPaymentError);
    setChildComponent(null);
  }

  const onPaymentSuccess = (data, payload) => {
    if (Number(data.item) === payload.object.randomId) {
      payload.object.deposit_paid = true;
      makeRequest(setLoader, createBooking, payload, onSuccess, onError)
    } else {
      localStorage.removeItem('bookingPayload');
      localStorage.removeItem('bookingPaymentId');
      notify("Booking", "Please pay the deposit to complete the booking", "error");
    }
  }

  const onPaymentError = (data, res) => {
  }

  const onError = (res) => {
    notify("Booking", res.msg, "error");
  }

  const getDisabledDayDates = (date) => {

    let wholeDate = null
    if (date.length > 0) {
      date = date[0];
    }
    if (date.unix) {
      wholeDate = moment.unix(date.unix);
    } else {
      wholeDate = moment(date);
    }
    // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
    const dayOfWeek = wholeDate.day();
    // If the day of the week is Sunday (0), disable the day

    if (dayOfWeek === 0 && !selectedBookingType.range) {
      notify("Error", wholeDate.format('YYYY-MM-DD') + " not available please call if you want to book", 'error');
      setDisableBooking(true);
      return true
    }


    if (checkDisableDays(dayOfWeek)) {
      notify("Error", wholeDate.format('YYYY-MM-DD') + " not available please call if you want to book", 'error');
      setDisableBooking(true);
      return true
    }

    if (checkDisableDates(wholeDate.format('YYYY-MM-DD'))) {
      notify("Error", wholeDate.format('YYYY-MM-DD') + " not available please call if you want to book", 'error');
      setDisableBooking(true);
      return true
    }
    setDisableBooking(false);
  }

  const checkDisableDates = (date) => {
    let tell = false;
    deps.disableDates.forEach((each) => {
      if (date === each) {
        tell = true
      }
    })
    return tell;
  }

  const checkDisableDays = (dayOfWeek) => {
    let tell = false;
    deps.disableDays.forEach((each) => {
      if (dayOfWeek === each) {
        tell = true
      }
    })
    return tell;
  }

  const rules = {
    booking_type_id: [
      { required: true, message: "Please select booking type" },
    ],
    pet_type_id: [
      { required: true, message: 'Please select type', },
    ],
    client_id: [
      { required: true, message: 'Please select client', },
    ],
    deposit: [
      { required: true, message: 'Please select deposit status', },
    ],
    start_date: [
      { required: true, message: 'Please select start date', },
    ],
    end_date: [
      { required: true, message: 'Please select end date', },
      {
        validator: (_, value) => {
          return new Promise((resolve, reject) => {
            const startDate = form.getFieldValue('start_date');
            const endDate = value;

            if (startDate && endDate && endDate < startDate) {
              reject(new Error('End date cannot be less than start date'));
            } else {
              resolve();
            }
          });
        },
      },
    ],
    booking_dates: [
      { required: true, message: 'Please select end date', },
    ],
  };



  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            layout="vertical"
            name={ formName }
            onFinish={ onFinish }
          >
            <h5 className="da-mb-24 color-lightgrey">Booking Types</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 16 }>
                <Form.Item name="booking_type_id" label="Booking Type" rules={ rules.booking_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['booking_type_id']) }
                >
                  <Select
                    onChange={ onChangeBookingType }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a booking type"
                    options={ deps.bookingTypes }
                  />
                </Form.Item>
              </Col>
            </Row>
            <h5 className="da-mb-24 color-lightgrey">Booking Details</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 8 }>
                <Form.Item name="pet_type_id" label="Pet Type" rules={ rules.pet_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['pet_type_id']) }
                >
                  <Select
                    onChange={ onPetTypeChange }
                    disabled={ selectedBookingType === null ? true : false }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a pet type"
                    options={ deps.petTypes }
                  />
                </Form.Item>
              </Col>
              <Col xs={ 24 } lg={ 16 }>
                <Form.Item name="pets" label="Pets" rules={ rules.pets } className="da-mb-4"
                >
                  <Select
                    value={ totalSelectedPet }
                    onChange={ onPetChange }
                    disabled={ petData.length < 1 ? true : false }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    mode="multiple"
                    placeholder="Select a pets"
                    options={ petData }
                  />
                </Form.Item>
                (Please select all animals that are shearing the same Kennel/ Cabin)
              </Col>
            </Row>

            { selectedBookingType != null ?
              <>
                { selectedBookingType.range ?
                  <Row gutter={ 16 }>
                    <Col xs={ 24 } lg={ 7 }>
                      <Form.Item name="start_date" label="Booking Start Date" rules={ rules.start_date } className="da-mb-16"
                        { ...getErrorProps(errors['start_date']) }
                      >
                        <DatePicker
                          disabled={ !totalSelectedPet ? true : false }
                          format={ "DD/MM/YYYY" }
                          onChange={ onSelectStartDate }
                          minDate={ moment().toDate() }
                          placeholder="Select a start date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={ 24 } lg={ 7 }>
                      <Form.Item name="end_date" label="Booking End Date" rules={ rules.end_date } className="da-mb-16"
                        { ...getErrorProps(errors['end_date']) }
                      >
                        <DatePicker
                          disabled={ !totalSelectedPet ? true : false }
                          format={ "DD/MM/YYYY" }
                          onChange={ onChangeEndDate }
                          minDate={ moment().toDate() }
                          placeholder="Select a end date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  :
                  <Row gutter={ 16 }>
                    <Col xs={ 24 } lg={ 14 }>
                      <Form.Item name="booking_dates" label="Booking Dates" rules={ rules.booking_dates } className="da-mb-4"
                        { ...getErrorProps(errors['booking_dates']) }
                      >
                        <DatePicker
                          disabled={ !totalSelectedPet ? true : false }
                          onChange={ onChangeDaycareDate }
                          minDate={ moment().toDate() }
                          format={ "YYYY/MM/DD" }
                          placeholder="Select a date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                          multiple
                          value={ null }
                          plugins={ [
                            <DatePanel />
                          ] }
                        />
                      </Form.Item>
                      <small>You can select multiple dates</small>
                    </Col>
                  </Row>
                }
              </>
              : null
            }

            <Row gutter={ 16 }>
              <Col lg={ 24 } className="da-mb-6">
                <Checkbox checked={ termOne } onChange={ (event) => { setTermOne(event.target.checked); setCurrentError({ onTermError: false }) } } id="termOne" /> <label htmlFor="termOne"><strong>Agree to our Dog or Cat Terms and Conditions</strong></label>
              </Col>
              <Col span={ 24 }>
                <Checkbox checked={ termTwo } onChange={ (event) => { setTermTwo(event.target.checked); setCurrentError({ onTermError: false }) } } id="termTwo" /> <label htmlFor="termTwo"><strong>{ webData ? webData.booking_term_text : "" }</strong></label>
              </Col>
              { currentError.onTermError ? <p style={ { color: 'red' } }>Please select to agree our tos</p> : null }
            </Row>

            <Row>
              <Col span={ 24 } className="da-mt-32 da-mb-32">
                <h3>Pricing</h3>
              </Col>
              <Col span={ 24 }>
                <BodyComponent>
                  <TableComponent loader={ loader } columns={ columns } dataSource={ bookingPetData } />
                </BodyComponent>
              </Col>
              <Col span={ 24 } className="da-mt-32 da-mb-32">
                <p>Please note you will be charged a { selectedBookingType ? selectedBookingType.deposit : 0 }% Deposit £ { bookingPetData.length > 0 && selectedBookingType ? (Number(selectedBookingType.deposit) * Number(bookingPetData[0].total_cost) / 100).toFixed(2) : 0 } on completing your booking, please note this is non refundable</p>
              </Col>
            </Row>
            <Row>
              <Col span={ 24 } className="da-text-right da-mt-24">
                <SaveButton disabled={ disableBooking } style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Pay Now " form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>
          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default CreateClientBooking

