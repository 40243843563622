import React, { useEffect } from "react";

const Iframe = ({ src, ...props }) => {
  const handleMessage = (event) => {
    // Check if the message is of the expected type
    if (event.data.type === "URL_CHANGE" && event.data.msg === "success") {
      props.onPaymentComplete(event.data);
    }
  };

  useEffect(() => {
    // Add the message event listener
    window.addEventListener("message", handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // The empty array ensures the effect only runs once on mount and unmount

  return (
    <iframe
      id="mainframe"
      src={ src }
      title="Main Iframe"
      width="100%" height="500" allowtransparency="true" style={ { border: 'none' } } allow="encrypted-media"
    ></iframe>
  );
};

export default Iframe;
