import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
const userData = JSON.parse(localStorage.getItem("user"));

const Profile = () => {
  const history = useHistory();

  useEffect(() => {
    if (userData) {
      history.push("/client-management/clients/view/" + userData.id);
    }
  }, []);

  return null;
}

export default Profile;