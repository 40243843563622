import React, { useEffect, useState, } from "react";
import { Form, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { createPetDiet } from "../requests";
import TextArea from "antd/lib/input/TextArea";


const formName = "createPetDiet";

const CreatePetDiet = (props) => {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);


  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object['pet_id'] = id;
    makeRequest(setLoader, createPetDiet, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("PetType Created", res.msg);
    props.onCreated(data);
  }

  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    errorList['email'] = err.email;
    errorList['role_id'] = err.role_id;
    errorList['company_id'] = err.company_id;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------

  return (

    <ModalComponent mainTitle="Create" subTitle="Pet Diet" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Form.Item name="title" rules={ rules.title } label="Title" className="da-mb-16"
          { ...getErrorProps(errors['title']) }>
          <Input placeholder="Diet Title" />
        </Form.Item>
        <Form.Item name="time" label="Select Time" rules={ rules.time } className="da-mb-8"
          { ...getErrorProps(errors['time']) }
        >
          <Select
            showSearch
            filterOption={(d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0}
            placeholder="Select a user role"
            options={ [{ "value": "Morning", "label": "Morning" }, { "value": "Afternoon", "label": "Afternoon" }, { "value": "Night", "label": "Night" }] }
          />
        </Form.Item>
        <Form.Item name="description" label="Description" className="da-mb-16">
          <TextArea placeholder="Description" />
        </Form.Item>


      </Form>
    </ModalComponent>
  );


}

export default CreatePetDiet

const rules = {
  title: [
    { required: true, message: 'Please input title!', },
  ],
  time: [
    { required: true, message: 'Please select time!', },
  ],

};
