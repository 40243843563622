import React, { useState, useEffect } from 'react';
import { Table, Button } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getSalesReport } from './requests';
import { HeaderComponent, BodyComponent, TableComponent } from "@comps/components";
import { Row, Col, Select, Input } from "antd";
import DatePicker from "react-multi-date-picker"
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Sales Report",
    breadcrumb: [
      {
        name: "Reports",
        path: "/report-management/sales"
      }
    ]
  }
}

const SalesReport = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [loader, setLoader] = useState(false);
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  useEffect(() => {
    getBookingReportData();
  }, []);

  const getBookingReportData = () => {
    let payload = {
      start_date: startDate,
      end_date: endDate
    }
    makeRequest(setLoader, getSalesReport, payload, onDataSuccess, onDataError);
  };

  const onDataSuccess = (data) => {
    setData(data);
  };

  const onDataError = (error) => {
    notify('error', error);
  };

  const onStartDate = (date) => {
    setStartDate(date);
    let payload = {
      start_date: date,
      end_date: endDate
    }
    makeRequest(setLoader, getSalesReport, payload, onDataSuccess, onDataError);
  }

  const onEndDate = (date) => {
    setEndDate(date);
    let payload = {
      start_date: startDate,
      end_date: endDate
    }
    makeRequest(setLoader, getSalesReport, payload, onDataSuccess, onDataError);
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Date & Time',
      dataIndex: 'date',
      render: (date) => date ? moment(date).format('DD/MM/YYYY') : null
    },
    {
      title: 'Total Price',
      dataIndex: 'total_price',
      render: (paid_deposit) => "£" + Number(paid_deposit).toFixed(2)
    },
    {
      title: 'Deposit Paid',
      dataIndex: 'paid_deposit',
      render: (paid_deposit) => "£" + Number(paid_deposit).toFixed(2)
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (record) => "£" + record.toFixed(2)
    },
  ];


  const handleExport = () => {
    const csvData = [];
    const headers = columns.map(col => col.title);
    csvData.push(headers);

    data.forEach(record => {
      const row = columns.map(col => record[col.dataIndex]);
      csvData.push(row);
    });

    const csvContent = 'data:text/csv;charset=utf-8,' + csvData.map(row => row.join(',')).join('\n');
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'sale-report.csv');
    document.body.appendChild(link);
    link.click();
  };

  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  const footer = () => {
    return (
      <Row gutter={ [16, 16] } className="da-mb-32 da-text-right">
        <Col xs={ 7 } lg={ 10 }>
          <strong>Total Price : £{ Number(data.reduce((a, b) => a + (Number(b.total_price) || 0), 0)).toFixed(2) }</strong>
        </Col>
        <Col xs={ 7 } lg={ 6 }>
          <strong>Total Paid : £{ Number(data.reduce((a, b) => a + (Number(b.paid_deposit) || 0), 0)).toFixed(2) }</strong>
        </Col>
        <Col xs={ 7 } lg={ 6 }>
          <strong>Total Balance : £{ Number(data.reduce((a, b) => a + (Number(b.balance) || 0), 0)).toFixed(2) }</strong>
        </Col>
      </Row>
    )
  }

  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
        <Button style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } className='da-mb-16' type="primary" icon={ <ExportOutlined /> } onClick={ handleExport }> Excel</Button>
      </HeaderComponent>
      <BodyComponent>
        <Row gutter={ [16, 16] } className="da-mb-32">
          <Col xs={ 24 } lg={ 6 }>
            <strong><label>Start Date</label></strong>
            <DatePicker
              onChange={ (date) => onStartDate(date) }
              format={ "DD/MM/YYYY" }
              placeholder="Select date"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
            />
          </Col>
          <Col xs={ 24 } lg={ 6 }>
            <strong><label>End Date</label></strong>
            <DatePicker
              onChange={ (date) => onEndDate(date) }
              format={ "DD/MM/YYYY" }
              placeholder="Select date"
              containerStyle={ {
                width: "100%"
              } }
              style={ { width: '100%', height: '40px' } }
            />
          </Col>
        </Row>
        <TableComponent loader={ loader } columns={ columns } footer={ footer } dataSource={ data } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } exportable={ true } />
      </BodyComponent>
    </>
  );
};

export default SalesReport;
