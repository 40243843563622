import React, { useEffect, useState } from "react";
import { Form, Input, DatePicker, Select } from "antd";
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { createBookingLimit, getDependencies } from "../requests";

const moment = require('moment');

const formName = "createBookingLimit";

const CreateBookingLimitByDate = (props) => {

  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [petTypes, setPetTypes] = useState([]);



  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    makeRequest(setLoader, getDependencies, null, onDataSuccess, onError);
  }

  const onDataSuccess = (res) => {
    setPetTypes(res.pet_types);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.date = moment(payload.date).format("YYYY-MM-DD");
    makeRequest(setLoader, createBookingLimit, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Booking Limit", res.msg);
    props.onCreated(data);
  }

  const onError = (res) => {
   if(res.code===403){
    notify("Booking Limit", res.msg, "error");
   }
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: props.crmStyle.bg_color, border: props.crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onCreated(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------
  
  return (

    <ModalComponent mainTitle="Create" subTitle="Booking Limit" visible={ true } footer={ footer } onCancel={ () => props.onCreated(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Form.Item name="date" rules={ rules.date } label="Date Type" className="da-mb-16"
          { ...getErrorProps(errors['date']) }
        >
          <DatePicker
            format={ "DD/MM/YYYY" }
            minDate={ moment().toDate() }
            placeholder="Select date"
            containerStyle={ {
              width: "100%"
            } }
            style={ { width: '100%', height: '40px' } }
          />
        </Form.Item>

        <Form.Item name="pet_type_id" rules={ rules.pet_type_id } label="Pet Type" className="da-mb-16"
          { ...getErrorProps(errors['pet_type_id']) }
        >
          <Select
            showSearch
            filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
            placeholder="Select pet type"
            options={ petTypes }
          />
        </Form.Item>

        <Form.Item name="booking_limit" rules={ rules.booking_limit } label="Limit" classlimit="da-mb-16"
          { ...getErrorProps(errors['booking_limit']) }
        >
          <Input type="number" placeholder="Type limit" />
        </Form.Item>


      </Form>
    </ModalComponent>
  );


}

export default CreateBookingLimitByDate

const rules = {
  booking_limit: [
    { required: true, message: 'Please input your limit!', },
  ],
  date: [
    { required: true, message: 'Please select date!', },
  ],
  pet_type_id: [
    { required: true, message: 'Please select pet type', },
  ],


};
