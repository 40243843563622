import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useSelector } from 'react-redux';
import { CancelButton, SaveButton, ModalComponent } from "@comps/components";
import { makeRequest, getErrorProps, notify } from "@utils/helpers";
import { addNewPetIntoBooking, getClientPetsByType } from "../requests";


const formName = "addNewPetForm";

const AddNewPet = (props) => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    let payload = { "client": props.data.client_id, "booking_id": props.data.id }
    makeRequest(setLoader, getClientPetsByType, payload, onDataSuccess, onError);
  }

  const onDataSuccess = (data, res) => {
    setData(data);
  }

  const onFinish = (data) => {
    let payload = { "object": data }
    payload.object.booking_id = props.data.id;
    makeRequest(setLoader, addNewPetIntoBooking, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    props.onAddedSuccess(data);
  }



  const onError = (err) => {
    let errorList = [];
    errorList['name'] = err.name;
    setErrors(errorList);
  }

  // ------------------------------------
  // Start footer buttons array
  // ------------------------------------
  const footer = [
    <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } form={ formName } key="create_button" htmlType="submit" state={ loader } />,
    <CancelButton key="close_button" onClick={ () => props.onAddedSuccess(false) } />
  ];
  // ------------------------------------
  // Eend footer buttons array
  // ------------------------------------


  return (

    <ModalComponent mainTitle="Add New" subTitle="Pet" visible={ true } footer={ footer } onCancel={ () => props.onAddedSuccess(false) }>

      <Form
        layout="vertical"
        name={ formName }
        onFinish={ onFinish }
      >
        <Form.Item gutter={ 34 } name="pet_id" label="Available Pet" rules={ rules.pet_id } className="da-mb-16"
          { ...getErrorProps(errors['pet_id']) }
        >
          <Select
            showSearch
            filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
            placeholder="Select a pet"
            options={ data }
          />
        </Form.Item>


      </Form>
    </ModalComponent>
  );


}

export default AddNewPet

const rules = {
  pet_id: [
    { required: true, message: 'Please select pets!', },
  ],

};
