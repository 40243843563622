import React, { useState, useEffect } from "react";
import { TableComponent, ActionComponent, HeaderComponent, BodyComponent, ButtonComponent } from "@comps/components";
import { useParams, useHistory } from "react-router-dom";
import { makeRequest, removeById, notify } from '@utils/helpers';
import { getBookingPetRequest, deleteBookingPet, getBookingPetCheckinRequest, getBookingPetCheckoutRequest } from './requests';
import { ContainerOutlined } from '@ant-design/icons';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from "antd";
const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Booking Pets",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      }
    ]
  }
}

const IndexBookingPet = () => {
  const crmStyle = useSelector(state => state.common.crmStyle);
  const { id } = useParams();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [childComponent, setChildComponent] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });

  const columns = [
    {
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      sorter: true,
      width: '100px',
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      render: (each) => each ? moment(each).format('DD/MM/YYYY') : null
    },
    {
      key: 'pet_name',
      title: 'Pet Name',
      dataIndex: 'pet_name',
      sorter: true,
    },
    {
      key: 'pet_type_name',
      title: 'Pet Type',
      dataIndex: 'pet_type_name',
      sorter: true,
    },
    {
      key: 'owner_name',
      title: 'Owner',
      dataIndex: 'owner_name',
      sorter: true,
    },
    {
      key: 'check_in',
      title: 'Check In',
      sorter: true,
      render: (record) => {
        return record.checked_in ? <>{ moment(record.checked_in).format('DD/MM/YYYY') }</> : record.checking_allow ? <ButtonComponent type="primary" style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ () => onCheckIn(record) }>Check In</ButtonComponent> : null;
      }
    },
    {
      key: 'check_out',
      title: 'Check Out',
      sorter: true,
      render: (record) => {
        return record.checkout_allow && !record.checked_out && record.checked_in ? <ButtonComponent type="primary" style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } onClick={ () => onCheckOut(record) }>Check Out</ButtonComponent> : <>{ record.checked_out ? moment(record.checked_out).format('DD/MM/YYYY') : null }</>;
      }
    },
    {
      key: "actions",
      title: 'Actions',
      render: (record) => <><Tooltip title="View Invoice" ><Button onClick={ () => history.push(`/invoice/${record.invoice}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: '#50a5f1', fontSize: '18px' } }><Icon icon="uil-invoice" /></Button></Tooltip>
        <Tooltip title="Pet Care Plan" ><Button onClick={ () => history.push(`/pet-management/pets/careplan/${record.pet_id}`) } className="da-px-10 da-my-0" type="link" size="middle" style={ { color: 'green', fontSize: '18px' } }><ContainerOutlined /></Button></Tooltip><ActionComponent each={ record } onDelete={ onDelete } /></>
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    makeRequest(setLoader, getBookingPetRequest, id, onDataSuccess, onError);
  }

  const onDataSuccess = (res, response) => {
    setDataSource(response.data);
    setTotalRecords(response.total);
  }

  const onCheckIn = (payload) => {
    makeRequest(setLoader, getBookingPetCheckinRequest, payload.id, onCheckInSuccess, onError);
  }

  const onCheckInSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onCheckOut = (payload) => {
    makeRequest(setLoader, getBookingPetCheckoutRequest, payload.id, onCheckOutSuccess, onError);
  }

  const onCheckOutSuccess = (res, response) => {
    notify("Pet", response.msg);
    getData();
  }

  const onDelete = (payload) => {
    makeRequest(setLoader, deleteBookingPet, payload, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, res) => {
    getData();
    notify("Pet", res.msg)
  }

  const onError = (error, msg) => {
    //
  }


  const handleTableChange = (page, fil, sorter) => {
    let payload = {
      ...pagination,
      current: page.current,
      pageSize: page.pageSize,
      sortName: sorter.field || 'id',
      sortType: sorter.order === 'ascend' ? 'asc' : 'desc',
    };
    setPagination(payload);
  }

  return (
    <>
      { childComponent }
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <TableComponent loader={ loader } columns={ columns } dataSource={ dataSource } pagination={ { ...pagination, total: totalRecords } } onChange={ handleTableChange } />
      </BodyComponent>
    </>
  );
}

export default IndexBookingPet;