import React from "react";
import { Route, Switch } from "react-router-dom";

import IndexBooking from "./bookings/IndexBooking";
import CreateBooking from "./bookings/components/CreateBooking";
import EditBooking from "./bookings/components/EditBooking";
import IndexBookingPet from "./bookingpets/IndexBookingPet";

//Client Routes
import IndexClientBooking from "./clientbookings/IndexClientBooking";
import CreateClientBooking from "./clientbookings/components/CreateClientBooking";

//Past Bookings
import PastBookings from "./bookings/PastBookings"

const loginUser = JSON.parse(localStorage.getItem("user"));


const BookingModule = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.url}/bookings` } component={ loginUser ? loginUser.role_id === 2 ? IndexClientBooking : IndexBooking : IndexClientBooking } />
    <Route exact path={ `${match.url}/bookings/past` } component={ loginUser ? loginUser.role_id === 2 ? IndexClientBooking : PastBookings : IndexClientBooking } />
    <Route exact path={ `${match.url}/bookings/create` } component={ loginUser ? loginUser.role_id === 2 ? CreateClientBooking : CreateBooking : CreateClientBooking } />
    <Route exact path={ `${match.url}/bookings/edit/:id` } component={ EditBooking } />
    <Route exact path={ `${match.url}/bookings/pets/:id` } component={ IndexBookingPet } />

  </Switch>
);

export default BookingModule;
