import React from "react";
import { Link } from "react-router-dom";

export default function MenuLogo(props) {
    let webData = localStorage.getItem('webData');
    let webDataObj = JSON.parse(webData);
    return (
        <Link
            to="/"
            className="da-d-flex da-align-items-end"
            onClick={ props.onClose }
        >
            <img className="da-logo" src={ webDataObj ? webDataObj.site_logo ? process.env.REACT_APP_API_BASE_URL.slice(0, -4) + webDataObj.site_logo : "/noimage.jpg" : "/noimage.jpg" } alt="logo" />

            <span
                className="da-p1-body da-font-weight-700 da-text-color-black-40 da-mb-16 da-ml-4"
                style={ {
                    letterSpacing: -1.5
                } }
            >

            </span>
        </Link>
    );
}
