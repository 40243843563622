import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Checkbox, Skeleton } from "antd";
import { useHistory } from "react-router-dom";

import { SaveButton, BodyComponent, HeaderComponent, TableComponent } from "@comps/components";
import { makeRequest, makeRequestStateless, getErrorProps, notify } from "@utils/helpers";
import { createBooking, getBookingDependencies, getPetsByType, getPriceOfPet, getPriceByPets } from "../requests";
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import { useSelector } from 'react-redux';

const moment = require('moment');

const pageConfig = {
  headers: {
    title: "Make Booking",
    breadcrumb: [
      {
        name: "Bookings",
        path: "/booking-management/bookings"
      },
      {
        name: "Make Booking",
        path: "/booking-management/bookings/create"
      }
    ]
  }
}
const formName = "createBooking";
const CreateBooking = (props) => {
  const [form] = Form.useForm();
  const crmStyle = useSelector(state => state.common.crmStyle);
  const [webData, setWebData] = useState(localStorage.getItem('webData') ? JSON.parse(localStorage.getItem('webData')) : {});
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState([]);
  const [petData, setPetData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [termOne, setTermOne] = useState(false);
  const [termTwo, setTermTwo] = useState(false);
  const [bookingPetData, setBookingPetData] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [totalBookingDays, setTotalBookingDays] = useState(0);
  const [totalSelectedPet, setTotalSelectedPet] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [limit, setLimit] = useState(null);
  const [selectedPetType, setSelectedPetType] = useState([]);
  const [currentError, setCurrentError] = useState([]);
  const [bookingEndDate, setBookingEndDate] = useState(null);
  const [selectedBookingType, setSelectedBookingType] = useState(null);
  const history = useHistory();
  const [deps, setDeps] = useState({
    bookingTypes: [],
    clients: [],
    petTypes: [],
    disableDays: [],
    disableDates: []
  });

  const getSelectFieldsData = () => {
    makeRequest(setLoader, getBookingDependencies, null, onDependencySuccess, null);
  }

  const columns = [
    {
      key: 'total_select_pets',
      title: 'Total Selected Pets',
      dataIndex: 'total_select_pets',
      sorter: false,
    },
    {
      key: 'selected_pet_type',
      title: 'Selected Pet Type',
      dataIndex: 'selected_pet_type',
      sorter: false,
    },
    {
      key: 'selected_booking_type',
      title: 'Selected Booking Type',
      dataIndex: 'selected_booking_type',
      sorter: false,
    },
    {
      key: 'total_days',
      title: 'Total Days',
      dataIndex: 'total_days',
      sorter: false,
    },
    {
      key: 'total_cost',
      title: 'Total Cost',
      dataIndex: 'total_cost',
      sorter: false,
    },
  ];

  useEffect(() => {
    getSelectFieldsData();
    // eslint-disable-next-line
  }, []);

  const onPetTypeChange = (value) => {
    let payload = { "client": selectedClient, "pet_type": value }
    setSelectedPetType(value);
    setLimit(deps.petTypes.find((obj) => obj.value === value).limit);
    makeRequestStateless(getPetsByType, payload, onPetSuccess, null);
  }

  const onChangeBookingType = (value) => {
    setBookingPetData([])
    let obj = deps.bookingTypes.find((obj) => obj.value === value);
    setSelectedBookingType(obj);
  }

  const onPetChange = (value) => {
    setTotalSelectedPet(value.length);

  }

  const onPriceSuccess = (res, petsno, value) => {
    let obj = [{
      "total_select_pets": petsno,
      "selected_pet_type": res.pet_type_name,
      "selected_booking_type": res.booking_type.name,
      "total_days": bookingEndDate ? totalBookingDays : 0,
      "total_cost": res.prices * bookingEndDate ? totalBookingDays : 0,
      "price": res.prices
    }]

    setBookingPetData(obj);
    setTotalPrice(res.prices);
    onChangeEndDate(value, value, obj);
  }



  const onPriceDataError = (err, res) => {
    notify("Error", res.msg, 'error');
    setTotalSelectedPet(null);
  }

  const onChangeEndDate = (valueEndDate, valueStartDate, bookingDataObj) => {
    if (valueStartDate === null || valueStartDate === undefined) {
      // notify("Error", "Please select start date", 'error');
      return;
    }


    //check if end date is less than start date
    if (valueEndDate && valueStartDate && valueEndDate.unix < valueStartDate.unix) {
      notify("Error", "End date should be greater than start date", 'error');
      setBookingEndDate(moment.unix(valueStartDate.unix).format('DD/MM/YYYY'));
      return;
    }
    if (valueEndDate.unix && valueStartDate.unix) {
      setBookingEndDate(valueEndDate);
      let startdate = moment.unix(valueStartDate.unix).format('YYYY-MM-DD');
      let enddate = moment.unix(valueEndDate.unix).format('YYYY-MM-DD');
      let bookingDates = [];
      let currentDate = moment(startdate);
      let endDate = moment(enddate);
      while (currentDate <= endDate) {
        let day = moment(currentDate).day();
        if (deps.disableDays.includes(day) || deps.disableDates.includes(currentDate.format('YYYY-MM-DD'))) {

        } else {
          bookingDates.push(currentDate.format('YYYY-MM-DD'));
        }
        currentDate = moment(currentDate).add(1, 'days');
      }

      bookingDataObj[0].total_days = bookingDates.length;
      bookingDataObj[0].total_cost = bookingDates.length * bookingDataObj[0].price;
      setTotalBookingDays(bookingDates.length);
      setBookingPetData([...bookingDataObj]);
    } else {
      bookingDataObj[0].total_days = valueEndDate.length;
      bookingDataObj[0].total_cost = valueEndDate.length * bookingDataObj.price;
      setTotalBookingDays(valueEndDate.length);
      setBookingPetData([...bookingDataObj]);
    }
  }

  const onPetSuccess = (data, res) => {
    setPetData(res.data);
  }


  const onDependencySuccess = (data, res) => {
    if (data.bookingTypes.length === 0) {
      notify("Booking", "Please create booking types first", 'error');
    }
    setDeps({
      bookingTypes: data.bookingTypes,
      clients: data.clients,
      petTypes: data.petTypes,
      disableDays: data.disabledDays,
      disableDates: data.disableDates
    });
  }
  const onFinish = (data) => {
    if (totalSelectedPet < 1) {
      notify("Error", "Please select pets", 'error');

      return;
    }
    if (!termOne || !termTwo) {
      setCurrentError({ onTermError: true });
      return;
    }
    let payload = { "object": data }
    if (data.booking_dates && data.booking_dates.length > 0) {
      let bookingDates = data.booking_dates.map((date) => {
        return moment.unix(date.unix).format('YYYY-MM-DD');
      })
      payload.object.booking_dates = bookingDates.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });
      payload.object.start_date = data.booking_dates[0];
      payload.object.user_id = JSON.parse(localStorage.getItem('user')).id
      payload.object.end_date = data.booking_dates[data.booking_dates.length - 1];
      payload.object.total_days = bookingDates.length;
    } else {

      payload.object.start_date = moment.unix(data.start_date.unix).format('YYYY-MM-DD');
      payload.object.end_date = moment.unix(bookingEndDate.unix).format('YYYY-MM-DD');
      let bookingDates = [];
      let currentDate = moment(payload.object.start_date);
      let endDate = moment(payload.object.end_date);
      while (currentDate <= endDate) {
        bookingDates.push(currentDate.format('YYYY-MM-DD'));
        currentDate = moment(currentDate).add(1, 'days');
      }
      bookingDates.sort(function (a, b) {
        a = a.split('/').reverse().join('');
        b = b.split('/').reverse().join('');
        return a > b ? 1 : a < b ? -1 : 0;
      });
      payload.object.booking_dates = bookingDates;
      payload.object.total_days = bookingDates.length;
    }
    if (payload.object.total_days < 1) {
      notify("Error", "Please select valid dates end date can not be less than start date", 'error');
      return;
    }
    payload.object.deposit_paid = data.deposit;
    payload.object.agree_terms = termOne;
    payload.object.limit = limit;
    payload.object.range = selectedBookingType.range;
    payload.object.agree_terms_two = termTwo;
    payload.object.client_id = selectedClient;
    payload.object.pet_pet_price = totalPrice / totalSelectedPet;
    payload.object.totalPrice = totalPrice * payload.object.total_days;
    payload.object.totalPets = totalSelectedPet;
    payload.object.status = "pending";
    makeRequest(setLoader, createBooking, payload, onSuccess, onError);
  }

  const onSuccess = (data, res) => {
    notify("Booking", res.msg);
    history.push(`/booking-management/bookings`);
  }

  const onError = (res) => {
    notify("Booking", res.msg, "error");
  }

  const onSelectStartDate = (value) => {
    let date = value.unix;
    setStartDate(value);
    setTotalBookingDays(0);
    if (date) {

      let date = moment.unix(value.unix).format('YYYY-MM-DD');
      let payload = {
        "pet_type_id": selectedPetType,
        "booking_type_id": selectedBookingType.value,
        "pet_no": totalSelectedPet,
        "date": date
      }
      makeRequestStateless(getPriceByPets, payload, (res) => onPriceSuccess(res, totalSelectedPet, value), onPriceDataError);
    }
  }

  const onChangeDaycareDate = (value) => {
    if (value.length > 0) {
      let date = moment.unix(value[0].unix).format('YYYY-MM-DD');
      let payload = {
        "pet_type_id": selectedPetType,
        "booking_type_id": selectedBookingType.value,
        "pet_no": totalSelectedPet,
        "date": date
      }
      setTotalBookingDays(value.length)
      makeRequestStateless(getPriceByPets, payload, (res) => onPriceDaycareSuccess(res, totalSelectedPet, value.length), onPriceDataError);
    } else {
      setBookingPetData([])
    }
  }

  const onPriceDaycareSuccess = (res, petsno, totaldays) => {
    let obj = [{
      "total_select_pets": petsno,
      "selected_pet_type": res.pet_type_name,
      "selected_booking_type": res.booking_type.name,
      "total_days": totaldays,
      "total_cost": res.prices * totaldays
    }]

    setTotalPrice(res.prices);
    setBookingPetData(obj);
  }

  const getDisabledDayDates = ({ date }) => {
    let wholeDate = moment.unix(date.unix);
    // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
    const dayOfWeek = wholeDate.day();
    // If the day of the week is Sunday (0), disable the day

    if (checkDisableDays(dayOfWeek)) {
      return {
        disabled: true,
        hidden: false
      };
    }


    if (checkDisableDates(wholeDate.format('YYYY-MM-DD'))) {
      return {
        disabled: true,
        hidden: false
      };
    }

  }

  const checkDisableDates = (date) => {
    let tell = false;
    deps.disableDates.forEach((each) => {
      if (date === each) {
        tell = true
      }
    })
    return tell;
  }

  const checkDisableDays = (dayOfWeek) => {
    let tell = false;
    deps.disableDays.forEach((each) => {
      if (dayOfWeek === each) {
        tell = true
      }
    })
    return tell;
  }

  if (webData === null) {
    return <div></div>
  }


  const rules = {
    booking_type_id: [
      { required: true, message: "Please select booking type" },
    ],
    pet_type_id: [
      { required: true, message: 'Please select type', },
    ],
    client_id: [
      { required: true, message: 'Please select client', },
    ],
    deposit: [
      { required: true, message: 'Please select deposit status', },
    ],
    start_date: [
      { required: true, message: 'Please select start date', },
    ],
    end_date: [
      { required: true, message: 'Please select end date', },
      {
        validator: (_, value) => {
          return new Promise((resolve, reject) => {
            const startDate = form.getFieldValue('start_date');
            const endDate = value;

            if (startDate && endDate && endDate < startDate) {
              reject(new Error('End date cannot be less than start date'));
            } else {
              resolve();
            }
          });
        },
      },
    ],
    booking_dates: [
      { required: true, message: 'Please select end date', },
    ],
  };


  if (loader) {
    return <><p>Please Wait...</p><Skeleton loading={ loader } active /></>
  }


  return (
    <>
      <HeaderComponent headers={ pageConfig.headers }>
      </HeaderComponent>
      <BodyComponent>
        <div className="da-p-32">
          <Form
            form={ form }
            layout="vertical"
            name={ formName }
            onFinish={ onFinish }
          >
            <h5 className="da-mb-24 color-lightgrey">Booking Types</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 16 }>
                <Form.Item name="booking_type_id" label="Booking Type" rules={ rules.booking_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['booking_type_id']) }
                >
                  <Select
                    onChange={ onChangeBookingType }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a booking type"
                    options={ deps.bookingTypes }
                  />
                </Form.Item>
              </Col>
            </Row>
            <h5 className="da-mb-24 color-lightgrey">Booking Details</h5>
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 7 }>
                <Form.Item name="client_id" label="Client" rules={ rules.client_id } className="da-mb-16"
                  { ...getErrorProps(errors['client_id']) }
                >
                  <Select
                    showSearch
                    loading={ loader }
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    onChange={ setSelectedClient }
                    placeholder="Select a client"
                    options={ deps.clients }
                  />
                </Form.Item>
              </Col>
              <Col xs={ 24 } lg={ 7 }>
                <Form.Item name="pet_type_id" label="Pet Type" rules={ rules.pet_type_id } className="da-mb-16"
                  { ...getErrorProps(errors['pet_type_id']) }
                >
                  <Select
                    onChange={ onPetTypeChange }
                    disabled={ selectedClient === null ? true : false }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a pet type"
                    options={ deps.petTypes }
                  />
                </Form.Item>
              </Col>
              <Col xs={ 24 } lg={ 8 }>
                <Form.Item { ...getErrorProps(errors['pets']) } name="pets" label="Pets" rules={ rules.pets } className="da-mb-4"
                >
                  <Select
                    value={ totalSelectedPet }
                    onChange={ onPetChange }
                    disabled={ petData.length < 1 ? true : false }
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    mode="multiple"
                    placeholder="Select a pets"
                    options={ petData }
                  />
                </Form.Item>
                (Please select all animals that are sharing the same Kennel/ Cabin)
              </Col>
            </Row>

            { selectedBookingType != null ?
              <>
                { selectedBookingType.range ?
                  <Row gutter={ 16 }>
                    <Col xs={ 24 } lg={ 7 }>
                      <Form.Item name="start_date" label="Booking Start Date" rules={ rules.start_date } className="da-mb-16"
                        { ...getErrorProps(errors['start_date']) }
                      >
                        <DatePicker
                          disabled={ !totalSelectedPet ? true : false }
                          format={ "DD/MM/YYYY" }
                          onChange={ onSelectStartDate }
                          minDate={ moment().toDate() }
                          placeholder="Select a start date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={ 24 } lg={ 7 }>
                      {/* <Form.Item name="end_date" label="Booking End Date" rules={ rules.end_date } className="da-mb-16"
                        { ...getErrorProps(errors['end_date']) }
                      > */}
                      <label><span style={ { color: 'red' } }>*</span> Booking End Date</label>
                      <div className="da-mt-8">
                        <DatePicker
                          disabled={ !totalSelectedPet && startDate.length === 0 ? true : false }
                          value={ bookingEndDate }
                          format={ "DD/MM/YYYY" }
                          onChange={ (value) => onChangeEndDate(value, startDate, bookingPetData) }
                          minDate={ moment().toDate() }
                          placeholder="Select a end date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                        />
                      </div>
                      {/* </Form.Item> */ }
                    </Col>
                  </Row>
                  :
                  <Row gutter={ 16 }>
                    <Col xs={ 24 } lg={ 14 }>
                      <Form.Item name="booking_dates" label="Booking Dates" rules={ rules.booking_dates } className="da-mb-4"
                        { ...getErrorProps(errors['booking_dates']) }
                      >
                        <DatePicker
                          disabled={ !totalSelectedPet ? true : false }
                          onChange={ onChangeDaycareDate }
                          minDate={ moment().toDate() }
                          format={ "DD/MM/YYYY" }
                          placeholder="Select a date"
                          containerStyle={ {
                            width: "100%"
                          } }
                          // mapDays={ getDisabledDayDates }
                          style={ { width: '100%', height: '40px' } }
                          multiple
                          value={ null }
                          plugins={ [
                            <DatePanel />
                          ] }
                        />
                      </Form.Item>
                      <small>You can select multiple dates</small>
                    </Col>
                  </Row>
                }
              </>
              : null
            }
            <Row gutter={ 16 }>
              <Col xs={ 24 } lg={ 14 }>
                <Form.Item name="deposit" label="Deposit" rules={ rules.deposit } className="da-mb-16"
                  { ...getErrorProps(errors['deposit']) }
                >
                  <Select
                    showSearch
                    filterOption={ (d1, d2) => d2?.label && d2.label.toLocaleString().toLowerCase().indexOf(d1.toLowerCase()) >= 0 }
                    placeholder="Select a deposit status"
                    options={ [{ "value": false, "label": "Not Paid" }, { "value": true, "label": "Deposit Paid" }] }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={ 16 }>
              <Col lg={ 24 } className="da-mb-6">
                <Checkbox checked={ termOne } onChange={ (event) => { setTermOne(event.target.checked); setCurrentError({ onTermError: false }) } } id="termOne" /> <label htmlFor="termOne"><strong>Agree to our Dog or Cat Terms and Conditions</strong></label>
              </Col>
              <Col span={ 24 }>
                <Checkbox checked={ termTwo } onChange={ (event) => { setTermTwo(event.target.checked); setCurrentError({ onTermError: false }) } } id="termTwo" /> <label htmlFor="termTwo"><strong>{ webData ? webData.booking_term_text : "" }</strong></label>
              </Col>
              { currentError.onTermError ? <p style={ { color: 'red' } }>Please select to agree our tos</p> : null }
            </Row>

            <Row>
              <Col span={ 24 } className="da-mt-32 da-mb-32">
                <h3>Pricing</h3>
              </Col>
              <Col span={ 24 }>
                <BodyComponent>
                  <TableComponent loader={ loader } columns={ columns } dataSource={ bookingPetData } />
                </BodyComponent>
              </Col>
              <Col span={ 24 } className="da-mt-32 da-mb-32">
                <p>Please note you will be charged a { selectedBookingType ? selectedBookingType.deposit : 0 }% Deposit £ { bookingPetData.length > 0 && selectedBookingType ? (Number(selectedBookingType.deposit) * Number(bookingPetData[0].total_cost) / 100).toFixed(2) : 0 } on completing your booking, please note this is non refundable</p>
              </Col>
            </Row>
            <Row>
              <Col span={ 24 } className="da-text-right da-mt-24">
                <SaveButton style={ { background: crmStyle.bg_color, border: crmStyle.border_color } } text="Create Booking " form={ formName } key="create_button" htmlType="submit" state={ loader } />
              </Col>
            </Row>
          </Form>
        </div>
      </BodyComponent>
    </>
  );
}

export default CreateBooking

