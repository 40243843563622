import React from "react";
import { Route, Switch } from "react-router-dom";

import PetReport from "./PetReport";
import BookingReport from "./BookingReport";
import SalesReport from "./SalesReport";
import PermissionDenied from './../dashboard/PermissionDenied';

const loginUser = JSON.parse(localStorage.getItem("user"));

const ReportModule = ({ match }) => (
  <Switch>
    <Route exact path={ `${match.url}/pets` } component={ loginUser ? loginUser.role_id === 2 ? PermissionDenied : PetReport : PermissionDenied } />
    <Route exact path={ `${match.url}/bookings` } component={ loginUser ? loginUser.role_id === 2 ? PermissionDenied : BookingReport : PermissionDenied } />
    <Route exact path={ `${match.url}/sales` } component={ loginUser ? loginUser.role_id === 2 ? PermissionDenied : SalesReport : PermissionDenied } />
  </Switch>
);

export default ReportModule;
